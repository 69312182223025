import { useMutation } from "react-query";

import { CreateForwardContractRequest } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { MutationHookResult } from "hooks/hookTypeHelpers";
import PricingOptionService from "services/PricingOptionService";
import useDashboardRefetchQueries from "hooks/crop-year/useDashboardRefetchQueries";

type UpdateForwardContractMutationResult = MutationHookResult<
  Awaited<ReturnType<PricingOptionService["updateForwardContact"]>>,
  Omit<CreateForwardContractRequest, "isManuallyCreated">
>;

export const UPDATE_PENDING_MANUAL_FORWARD_CONTRACT_QUERY_KEY =
  "update-pending-manual-forward-contract";

const useUpdatePendingManualForwardContract = (
  contractId?: string,
  cropYearId?: string,
  farmId?: string
): UpdateForwardContractMutationResult => {
  const pricingOptionService = usePricingOptionService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationFn: (forwardContract) => {
      if (contractId && cropYearId && farmId) {
        return forwardContract
          ? pricingOptionService.updateForwardContact({
              contractId,
              cropYearId,
              farmId,
              forwardContract: { ...forwardContract, isManuallyCreated: true },
            })
          : Promise.reject(
              new Error("Must provide valid forward contract information")
            );
      } else {
        return Promise.reject(
          new Error("Missing contract id, crop year id, or farm id")
        );
      }
    },
    mutationKey: [UPDATE_PENDING_MANUAL_FORWARD_CONTRACT_QUERY_KEY],
    onSuccess: () => refetch(),
  });
};

export default useUpdatePendingManualForwardContract;
