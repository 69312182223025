import { ReactElement, useCallback } from "react";
import { HStack, MenuItem, Spacer, Spinner, Text } from "@chakra-ui/react";
import useDownloadPricingData from "hooks/csv-download/useDownloadPricingData";
import { useCropYearContext } from "contexts/CropYearContext";
import useCustomToast from "hooks/useCustomToast";

const DownloadPricingDataMenuItem = (): ReactElement => {
  const { cropYear } = useCropYearContext();
  const { mutateAsync, isLoading } = useDownloadPricingData({ cropYear });
  const { onSuccessToast, onErrorToast } = useCustomToast();

  const onDownloadPricingData = useCallback(() => {
    mutateAsync()
      .then(() => {
        onSuccessToast({
          message: "Successfully downloaded Pricing Data.",
        });
      })
      .catch((err) => {
        const errorMessage = err.response.data.error;

        return onErrorToast({
          message:
            errorMessage ??
            "Failed to download Pricing Data. Please try again.",
        });
      });
  }, [mutateAsync, onErrorToast, onSuccessToast]);

  return (
    <MenuItem isDisabled={isLoading} onClick={onDownloadPricingData}>
      <HStack>
        <Text>Download Pricing Data</Text>
        <Spacer />
        {isLoading ? <Spinner borderWidth="2px" size="sm" /> : null}
      </HStack>
    </MenuItem>
  );
};

export default DownloadPricingDataMenuItem;
