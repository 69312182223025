import { useInfiniteQuery } from "react-query";

import { FieldListDto } from "api";
import { useFieldService } from "contexts/ServiceProvider";
import { BaseApiError, PaginationMeta, QueryParams } from "services/apiHelpers";
import { FieldsListSortColumns } from "services/FieldService";

export type FieldListItem = Pick<
  FieldListDto,
  | "id"
  | "fieldNumber"
  | "totalAcres"
  | "tonsPerAcre"
  | "dairy"
  | "legalDescription"
  | "commonName"
  | "farms"
  | "region"
>;

type GetFieldsListResponse = { fields: FieldListItem[]; meta: PaginationMeta };

export const GET_FIELDS_QUERY_KEY = "get-fields-list";

const useFieldsList = ({
  cropYearId,
  farmId,
  queryParams,
}: {
  cropYearId?: string;
  farmId?: string;
  queryParams?: Omit<QueryParams<FieldsListSortColumns>, "page">;
}) => {
  const fieldService = useFieldService();

  return useInfiniteQuery<GetFieldsListResponse, BaseApiError>(
    [GET_FIELDS_QUERY_KEY, { cropYearId, farmId }, queryParams],
    ({ pageParam = 0 }) =>
      cropYearId && farmId
        ? fieldService
            .getFields({
              cropYearId,
              queryParams: { ...queryParams, page: pageParam },
              farmId,
            })
            .then(({ data, meta }) => ({
              fields: data,
              meta,
            }))
        : Promise.reject("Must select a valid crop year and farm"),
    { enabled: !!cropYearId && !!farmId }
  );
};

export default useFieldsList;
