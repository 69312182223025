import { Button, Text, VStack } from "@chakra-ui/react";
import {
  Link as RLink,
  Navigate,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { CSVUploadJobResponseStatusEnum, FieldImportResponse } from "api";
import WithSpinner from "components/WithSpinner";
import useCsvUploadJob from "hooks/csv-upload/useCsvUploadJob";
import { ErrorStateIcon } from "icons";
import { useCallback } from "react";
import { csvChildPaths } from "routes/csv-uploads/csvPaths";

const CatastrophicErrorState = ({
  isLoading,
  onRetry,
  linkText,
  linkTo,
}: {
  isLoading: boolean;
  onRetry: (file: File) => Promise<FieldImportResponse>;
  linkText?: string;
  linkTo?: string;
}) => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [file, setFile] =
    useOutletContext<[File, (file: File | undefined) => void]>();
  const { data, isLoading: csvJobIsLoading } = useCsvUploadJob(jobId);
  const onNavigateToFileSelect = useCallback(() => {
    setFile(undefined);
    navigate(`../${csvChildPaths.fileSelect}`);
  }, [navigate, setFile]);
  if (
    data &&
    !csvJobIsLoading &&
    data.status !== CSVUploadJobResponseStatusEnum.Failed
  ) {
    return <Navigate to={`../${csvChildPaths.progress}/${jobId}`} />;
  }

  return (
    <WithSpinner isLoading={csvJobIsLoading}>
      <VStack p="80px" spacing={8}>
        <ErrorStateIcon />
        <Text color="steelGray" fontWeight="bold" w="400px" textAlign="center">
          An error occurred while processing your upload. Please try again.
        </Text>
        <VStack spacing={2}>
          <Button
            isLoading={isLoading}
            size="lg"
            onClick={() =>
              file
                ? onRetry(file)
                    .then((data) =>
                      navigate(`../${csvChildPaths.progress}/${data.jobId}`)
                    )
                    .catch(onNavigateToFileSelect)
                : onNavigateToFileSelect()
            }
            w="230px"
          >
            Retry Upload
          </Button>
          <Button
            as={RLink}
            size="lg"
            to={linkTo}
            variant="secondary"
            w="230px"
          >
            {linkText}
          </Button>
        </VStack>
      </VStack>
    </WithSpinner>
  );
};

export default CatastrophicErrorState;
