import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
} from "react";

import { Center, Spinner } from "@chakra-ui/react";

import { MeResponse } from "api";
import { useGetUserInfo } from "hooks/auth/useGetUserInfo";
import useAccessToken from "hooks/auth/useAccessToken";
import useSelectedFarmId from "hooks/useSelectedFarmId";

const userInfoContext = createContext<MeResponse | undefined>(undefined);

const UserInfoProvider = ({
  children,
  initialUserInfo,
}: PropsWithChildren<{ initialUserInfo?: MeResponse }>): ReactElement => {
  const { accessToken } = useAccessToken();

  const [farmId, setFarmId] = useSelectedFarmId();

  const { data, isLoading } = useGetUserInfo(
    !!accessToken,
    initialUserInfo,
    ({ isEmployee, farms }) => {
      if (!isEmployee && !farmId && farms[0]?.farmId) {
        setFarmId(farms[0].farmId);
      }
    }
  );

  if (isLoading) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    );
  }

  return (
    <userInfoContext.Provider value={data}>{children}</userInfoContext.Provider>
  );
};

export default UserInfoProvider;

export const useUserInfo = (): MeResponse => {
  const context = useContext(userInfoContext);
  if (!context) {
    throw new Error("UserInfoContext not mounted or failed to load user info");
  }

  return context;
};
