import { ReactElement } from "react";

import { useDisclosure } from "@chakra-ui/react";

import useCustomToast from "hooks/useCustomToast";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import haylagePaths from "routes/haylage";
import { AddFarmsToYearButton } from "components/AddFarmsToYear";
import useAssignFarmToHaylageYear from "hooks/api/haylage-year/useAssignFarmToHaylageYear";
import useSelectedHaylageYearId from "hooks/useSelectedHaylageYearId";

const AddFarmsToHaylageYearButton = ({
  year,
  farmIds,
  isDisabled,
  onSuccess,
}: {
  year: number | undefined;
  farmIds: Array<string>;
  isDisabled: boolean;
  onSuccess(): void;
}): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedHaylageYearId] = useSelectedHaylageYearId();
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const navigate = useNavigateWithQuery();

  const { mutateAsync, isLoading } = useAssignFarmToHaylageYear(
    selectedHaylageYearId
  );

  const onAddFarms = () => {
    if (farmIds.length > 0) {
      mutateAsync(farmIds)
        .then(() => {
          onSuccess();
          onSuccessToast({
            title: "Farms Added Successfully",
            message: `Selected farms have been added to Haylage ${year}`,
          });
          onClose();
          navigate(
            `/${haylagePaths.basePath}/${haylagePaths.children.allFarms}`
          );
        })
        .catch(() => {
          onErrorToast({
            title: "Error",
            message: `Selected farms were not added to Haylage ${year} Program, please try again`,
          });
          onClose();
        });
    }
  };

  return (
    <AddFarmsToYearButton
      programType="Haylage"
      year={year}
      isDisabled={isDisabled}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onAddFarms={onAddFarms}
      isLoading={isLoading}
    />
  );
};

export default AddFarmsToHaylageYearButton;
