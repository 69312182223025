import { useInfiniteQuery } from "react-query";
import { useUserService } from "contexts/ServiceProvider";
import { UserListSortColumns } from "services/UserService";
import {
  BaseApiError,
  PaginationMeta,
  QueryParams,
  transformProfilePictureResponse,
} from "services/apiHelpers";
import {
  FarmUserClaimDto,
  UserResponse,
  UserRoleDto,
  UserStatusDto,
} from "api";

type GetUsersListResponse = { users: UserListItem[]; meta: PaginationMeta };

export type UserListItem = {
  id: string;
  name: string;
  profilePicture?: ProfilePicture;
  email: string;
  phoneNumber?: string;
  farms?: FarmUserClaimDto[];
  roles: UserRoleDto[];
  status: UserStatusDto;
};

export const GET_USERS_QUERY_KEY = "get-users";

export const transformUserResponse = (user: UserResponse): UserListItem => {
  return {
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
    profilePicture: transformProfilePictureResponse(user.profilePicture),
    email: user.email,
    phoneNumber: user.phoneNumber,
    farms: user.farms,
    roles: user.role,
    status: user.status,
  };
};

const useUsersList = (
  queryParams?: Omit<QueryParams<UserListSortColumns>, "page"> & {
    farmId?: string;
  }
) => {
  const userService = useUserService();

  return useInfiniteQuery<GetUsersListResponse, BaseApiError>(
    [GET_USERS_QUERY_KEY, queryParams],
    ({ pageParam = 0 }) =>
      userService
        .getUsers({
          ...queryParams,
          page: pageParam,
        })
        .then(({ data, meta }) => ({
          users: data.map(transformUserResponse),
          meta,
        }))
  );
};

export default useUsersList;
