import { useQuery } from "react-query";

import { FarmListDto } from "api";
import { AddableFarmsSortColumns } from "components/AddFarmsToYear/addFarmsTableColumns";
import { useHaylageYearService } from "contexts/ServiceProvider";
import { BaseApiError, QueryParams } from "services/apiHelpers";

export const GET_HAYLAGE_YEAR_ADDABLE_FARMS_QUERY_KEY =
  "addable-farms-haylage-year";

const useGetAddableFarmsForHaylageYear = (
  haylageYearId?: string,
  sort?: Omit<QueryParams<AddableFarmsSortColumns>, "page">
) => {
  const haylageYearService = useHaylageYearService();

  return useQuery<FarmListDto[], BaseApiError>({
    queryKey: [GET_HAYLAGE_YEAR_ADDABLE_FARMS_QUERY_KEY, haylageYearId, sort],
    queryFn: () =>
      haylageYearId
        ? haylageYearService.getAddableFarmsForHaylageYear(haylageYearId, sort)
        : Promise.reject(new Error("Must have haylageYearId")),
  });
};

export default useGetAddableFarmsForHaylageYear;
