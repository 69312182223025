import { useMutation } from "react-query";

import { CreateForwardContractRequest } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { MutationHookResult } from "hooks/hookTypeHelpers";
import PricingOptionService from "services/PricingOptionService";
import useDashboardRefetchQueries from "hooks/crop-year/useDashboardRefetchQueries";

type CreateForwardContractMutationResult = MutationHookResult<
  Awaited<ReturnType<PricingOptionService["createForwardContract"]>>,
  Omit<CreateForwardContractRequest, "isManuallyCreated">
>;

export const CREATE_FORWARD_CONTRACT_QUERY_KEY = "create-forward-contract";

const useCreateForwardContract = (
  cropYearId: string | undefined,
  farmId: string | undefined,
  isManuallyCreated: boolean
): CreateForwardContractMutationResult => {
  const pricingOptionService = usePricingOptionService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationFn: (forwardContract) => {
      if (cropYearId && farmId) {
        return forwardContract
          ? pricingOptionService.createForwardContract({
              cropYearId,
              farmId,
              forwardContract: { ...forwardContract, isManuallyCreated },
            })
          : Promise.reject(
              new Error("Must provide valid forward contract information")
            );
      } else {
        return Promise.reject(new Error("Missing crop year id and farm id"));
      }
    },
    mutationKey: [
      CREATE_FORWARD_CONTRACT_QUERY_KEY,
      { cropYearId },
      { farmId },
    ],
    onSuccess: () => {
      refetch();
    },
  });
};

export default useCreateForwardContract;
