import { Navigate, useOutletContext, useParams } from "react-router-dom";
import { Center, Stack, Text } from "@chakra-ui/react";

import { CSVUploadJobResponseStatusEnum } from "api";
import UploadBox from "components/UploadBox";
import { CsvStep, UploadProgressSpinner } from "components/CsvUpload";
import useCsvUploadJob from "hooks/csv-upload/useCsvUploadJob";
import csvPaths from "routes/csv-uploads/csvPaths";
import WithSpinner from "components/WithSpinner";

const FieldsResultsNoErrors = () => {
  const [, setFile] =
    useOutletContext<[File, (file: File | undefined) => void]>();
  const { jobId } = useParams();
  const { data, isLoading } = useCsvUploadJob(jobId, (data) => {
    if (data?.status === CSVUploadJobResponseStatusEnum.Success) {
      setFile(undefined);
    }
  });
  const isComplete = data?.status === CSVUploadJobResponseStatusEnum.Success;
  if (isComplete && (data?.itemsWithErrors ?? 0) > 0) {
    return (
      <Navigate
        to={`../${csvPaths.children.fields.children.results}/${jobId}`}
      />
    );
  }

  return (
    <>
      <CsvStep currentStep={3} totalSteps={3} description="Upload Complete" />
      <WithSpinner isLoading={isLoading}>
        <UploadBox h="unset" py="134px" bg="gray.50" borderColor="gray.200">
          <Center>
            <Stack alignItems="center">
              <UploadProgressSpinner isLoading={false} />
              <Text fontWeight="bold">
                {`${data?.itemsProcessedSuccessfully} out of ${data?.totalItems} fields successfully uploaded.`}
                <br />
                Go to the Fields list to see fields added to the crop year.
              </Text>
            </Stack>
          </Center>
        </UploadBox>
      </WithSpinner>
    </>
  );
};

export default FieldsResultsNoErrors;
