import { useMutation, useQueryClient } from "react-query";

import { useFarmService } from "contexts/ServiceProvider";
import { GET_USERS_QUERY_KEY } from "hooks/user/useUsersList";

const REMOVE_USERS_FROM_FARM_MUTATION_KEY = "remove-users-from-farm";

const useRemoveUsersFromFarm = () => {
  const farmService = useFarmService();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [REMOVE_USERS_FROM_FARM_MUTATION_KEY],
    mutationFn: ({
      farmId,
      userIds,
    }: {
      farmId?: string;
      userIds: string[];
    }) => {
      if (!farmId) {
        throw new Error("Missing farmId");
      }

      return farmService.removeUsersFromFarm(farmId, userIds);
    },
    onSuccess: () => {
      queryClient.refetchQueries([GET_USERS_QUERY_KEY]);
    },
  });
};

export default useRemoveUsersFromFarm;
