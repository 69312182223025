import { useMutation } from "react-query";
import { useFarmService } from "contexts/ServiceProvider";
import { queryClient } from "contexts/ReactQueryProvider";
import { FARM_DOCUMENT_LINK_QUERY_KEY } from "hooks/farm/useFarmDocumentList";

const UPLOAD_FARM_DOCUMENTS_LINK_QUERY_KEY = "upload-farm-documents-csv";

const useUploadFarmDocumentsCsv = () => {
  const farmService = useFarmService();

  return useMutation({
    mutationKey: [UPLOAD_FARM_DOCUMENTS_LINK_QUERY_KEY],
    mutationFn: ({ csvFile }: { csvFile: File }) =>
      farmService.uploadFarmDocumentsCsv(csvFile),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FARM_DOCUMENT_LINK_QUERY_KEY],
      });
    },
  });
};

export default useUploadFarmDocumentsCsv;
