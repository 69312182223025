import { useMutation, useQueryClient } from "react-query";

import { FARM_DOCUMENT_LINK_QUERY_KEY } from "./useFarmDocumentList";
import { SILAGE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY } from "../crop-year/useSilageYearFarmDocuments";
import { useFarmService } from "contexts/ServiceProvider";

const DELETE_FARM_DOCUMENTS_MUTATION_KEY = "delete-farm-documents";

const useDeleteFarmDocuments = (farmId: string | undefined) => {
  const farmService = useFarmService();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [DELETE_FARM_DOCUMENTS_MUTATION_KEY, { farmId }],
    mutationFn: (documentIds: string[]) =>
      farmId && documentIds
        ? farmService.deleteFarmDocuments(documentIds, farmId)
        : Promise.reject(new Error("Missing farmId and/ or documentIds")),
    onSuccess: () =>
      Promise.all([
        queryClient.refetchQueries([FARM_DOCUMENT_LINK_QUERY_KEY]),
        queryClient.refetchQueries([SILAGE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY]),
      ]),
  });
};

export default useDeleteFarmDocuments;
