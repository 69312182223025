import { useMutation } from "react-query";

import { useAuthService } from "contexts/ServiceProvider";
import AuthService from "services/AuthService";
import { MutationHook, MutationHookResult } from "hooks/hookTypeHelpers";

export type ResetPasswordRequestProps = {
  password: string;
  confirmPassword: string;
  token: string;
};

type ResetPasswordMutationResult = MutationHookResult<
  Awaited<ReturnType<AuthService["resetPassword"]>>,
  ResetPasswordRequestProps
>;

const useResetPasswordMutation = (): ResetPasswordMutationResult => {
  const authService = useAuthService();

  return useMutation({
    mutationKey: ["reset-password"],
    mutationFn: ({ password, confirmPassword, token }) =>
      authService.resetPassword(password, confirmPassword, token),
  });
};

const useResetPassword = (): MutationHook<
  ResetPasswordMutationResult,
  "onResetPassword"
> => {
  const {
    mutate: onResetPassword,
    mutateAsync: onResetPasswordAsync,
    ...rest
  } = useResetPasswordMutation();

  return { onResetPassword, onResetPasswordAsync, ...rest };
};

export default useResetPassword;
