import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import { Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import userRegistrationPaths from "routes/user-registration/userRegistrationPaths";
import { extractResponseErrorMessage } from "services/apiHelpers";
import SubmitButton from "components/SubmitButton";
import { useRegistrationForm } from "contexts/RegistrationFormContext";
import useInviteDetails from "hooks/user/useInviteDetails";
import useNavigateToErrorPage from "hooks/useNavigateToErrorPage";
import { userRegistrationErrorStates } from "pages/user-registration/userRegistrationErrorStates";
import { basicUserInfoFormSchema } from "../schemas/basicUserInfoFormSchema";
import BasicUserInfoInputs from "./BasicUserInfoInputs";
import useFormValidation from "hooks/useFormValidation";

const BasicUserInfoForm = ({
  onSubmitSuccess,
}: {
  onSubmitSuccess?: () => void;
}): ReactElement => {
  const { basicUserInfo, onSetBasicUserInfo, inviteToken } =
    useRegistrationForm();

  const methods = useForm<BasicUserInfo>({
    resolver: yupResolver(basicUserInfoFormSchema),
    defaultValues: {
      firstName: basicUserInfo.firstName,
      lastName: basicUserInfo.lastName,
      email: basicUserInfo.email,
      password: basicUserInfo.password,
      confirmPassword: basicUserInfo.confirmPassword,
    },
  });
  const requiredFieldsNotFilled = useFormValidation(methods.watch());

  const navigate = useNavigate();
  const navigateToErrorPage = useNavigateToErrorPage(
    userRegistrationErrorStates,
    userRegistrationPaths.children.error
  );

  const { isLoading } = useInviteDetails(
    inviteToken,
    (data) => {
      const newUserInfo = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.emailAddress,
        password: undefined,
        confirmPassword: undefined,
      };
      methods.reset(newUserInfo);
      onSetBasicUserInfo(newUserInfo);
    },
    (error) => {
      const errorMsg = extractResponseErrorMessage(error?.response);
      if (userRegistrationErrorStates.USER_REGISTERED.match?.test(errorMsg)) {
        // During this stage, if the user is already registered, redirect to the login automatically.
        navigate(userRegistrationErrorStates.USER_REGISTERED.linkTo ?? "", {
          replace: true,
        });
      } else {
        navigateToErrorPage(errorMsg);
      }
    }
  );

  return (
    <FormProvider {...methods}>
      <Stack
        as="form"
        w="100%"
        onSubmit={methods.handleSubmit((data) => {
          onSetBasicUserInfo(data);
          onSubmitSuccess?.();
        })}
      >
        <BasicUserInfoInputs isLoading={isLoading} />
        <SubmitButton
          w="100%"
          size="lg"
          submitDisabled={requiredFieldsNotFilled}
          isSubmitting={methods.formState.isSubmitting}
          buttonText="Continue"
        />
      </Stack>
    </FormProvider>
  );
};

export default BasicUserInfoForm;
