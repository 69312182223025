import { useQuery } from "react-query";

import { CSVUploadJobResponse, CSVUploadJobResponseStatusEnum } from "api";
import { useCsvService } from "contexts/ServiceProvider";
import useRefetchFieldQueries from "hooks/field/useRefetchFieldQueries";
import { BaseApiError } from "services/apiHelpers";

const CSV_JOB_POLLING_INTERVAL_MS = 3000;

const useCsvUploadJob = (
  jobId: string | undefined,
  onSuccess?: (data: CSVUploadJobResponse) => void
) => {
  const csvService = useCsvService();
  const { refetch: refetchFields } = useRefetchFieldQueries();

  return useQuery<CSVUploadJobResponse, BaseApiError>({
    queryKey: ["csv-upload-job", { jobId }],
    queryFn: () =>
      jobId ? csvService.getJob(jobId) : Promise.reject("Missing job ID."),
    enabled: !!jobId,
    refetchInterval: (data) =>
      data?.status === CSVUploadJobResponseStatusEnum.InProgress
        ? CSV_JOB_POLLING_INTERVAL_MS
        : false,
    onSuccess: async (data) => {
      if (data.status === CSVUploadJobResponseStatusEnum.Success) {
        await refetchFields();
      }
      onSuccess?.(data);
    },
  });
};

export default useCsvUploadJob;
