import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { Button, Center, Divider } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import {
  CreateCropYearRequest,
  CropYearDetailResponseTimeOfYearEnum,
  UpdateCropYearRequest,
} from "api";
import { PageHeader } from "components/page-components";
import SubmitButton from "components/SubmitButton";
import FormButtonGroup from "components/FormButtonGroup";
import ContentWrapper from "components/ContentWrapper";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import CropYearDetailInputs from "forms/crop-year-detail";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useCreateCropYear from "hooks/crop-year/useCreateCropYear";
import useConfirmationAlert from "hooks/alerts/useConfirmationAlert";
import useUpdateCropYear from "hooks/crop-year/useUpdateCropYear";
import useCustomToast from "hooks/useCustomToast";
import useFormValidation from "hooks/useFormValidation";
import { FORM_CONTENT_PX, FormStack } from "layouts/FormLayout";
import { CropYearDetailFields } from "./CropYearFormContext";
import CropYearDataWrapper from "./CropYearDataWrapper";
import TimeOfYearToggle from "./components/TimeOfYearToggle";

const transformCropYearData = (
  data: RemoveUndefined<CropYearDetailFields>
): UpdateCropYearRequest | CreateCropYearRequest => ({
  year: data.year,
  distanceAdjustmentRate: parseFloat(data.distanceAdjRate),
  averagePricingLockInDate: data.averagePricingLockInDate.toISOString(),
  defermentDateStart: data.defermentDateStart.toISOString(),
  firstPaymentDateWithoutDeferral:
    data.firstPaymentDateWithoutDeferral.toISOString(),
  growerLetterLink: data.growerLetterLink,
  growerLetterName: data.growerLetterName,
  regions: data.regionDetails,
  storageFeeStartDate: data.storageFeeStartDate.toISOString(),
  storageFeeRate: parseFloat(data.storageFeeRate),
  delayedPricingFinalDate: data.delayedPricingFinalDate.toISOString(),
});

export const EditCropYearForm = () => {
  const { id } = useParams();
  const navigate = useNavigateWithQuery();

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting, isDirty },
  } = useFormContext<CropYearDetailFields>();

  const cropYearDisplay = watch("year");

  const { onSubmitAsync } = useCreateCropYear();
  const { mutateAsync: onUpdateAsync } = useUpdateCropYear(id);

  const { onSuccessToast, onErrorToast } = useCustomToast();

  const {
    alert: startAlert,
    onOpen: onStartNew,
    isOpen: startAlertIsOpen,
  } = useConfirmationAlert({
    title: `Start ${cropYearDisplay} Year`,
    msg: `This action will start a new ${cropYearDisplay} crop year on the Riverview platform. Are you sure you want to continue?`,
    confirmButtonText: `Start ${cropYearDisplay}`,
    onConfirmAsync: () => {
      const formData = watch() as RemoveUndefined<CropYearDetailFields>;
      const data = transformCropYearData(formData) as CreateCropYearRequest;

      return onSubmitAsync(data)
        .then(() => {
          onSuccessToast({ message: "Successfully created crop year" });
          navigate(`/${dashboardPaths.basePath}`);
        })
        .catch(() => {
          onErrorToast({ message: "Failed to create crop year" });
        });
    },
  });

  const {
    alert: saveAlert,
    onOpen: onSave,
    isOpen: saveAlertIsOpen,
  } = useConfirmationAlert({
    title: "Save",
    msg: "This change will be applied to all farms for this year. Do you wish to continue?",
    confirmButtonText: "Save",
    onConfirmAsync: () => {
      const formData = watch() as RemoveUndefined<CropYearDetailFields>;
      const data = transformCropYearData(
        formData as RemoveUndefined<CropYearDetailFields>
      );

      return onUpdateAsync(data as UpdateCropYearRequest)
        .then(() => {
          onSuccessToast({ message: "Successfully updated crop year" });
          navigate(`/${dashboardPaths.basePath}`);
        })
        .catch(() => {
          onErrorToast({ message: "Failed to update crop year" });
        });
    },
  });

  const onCancel = useCallback(() => {
    navigate(`/${dashboardPaths.basePath}`);
  }, [navigate]);

  const requiredFieldsNotFilled = useFormValidation(watch());

  return (
    <Center w="100%" px={FORM_CONTENT_PX}>
      <ContentWrapper>
        {startAlert}
        {saveAlert}
        <TimeOfYearToggle isDisabled={isDirty} />
        <Divider my={6} />
        <FormStack onSubmit={handleSubmit(!id ? onStartNew : onSave)}>
          <PageHeader heading={`${cropYearDisplay} Details`}>
            <FormButtonGroup>
              <Button size="md" variant="outline" onClick={onCancel}>
                Cancel
              </Button>
              <SubmitButton
                submitDisabled={
                  !isDirty ||
                  requiredFieldsNotFilled ||
                  startAlertIsOpen ||
                  saveAlertIsOpen
                }
                isSubmitting={isSubmitting}
                buttonText={id ? "Save" : `Start ${cropYearDisplay}`}
              />
            </FormButtonGroup>
          </PageHeader>
          <CropYearDetailInputs
            isDisabled={
              watch("timeOfYear") ===
              CropYearDetailResponseTimeOfYearEnum.PostHarvest
            }
            disableCreationOnlyFields={!!id}
          />
        </FormStack>
      </ContentWrapper>
    </Center>
  );
};

const EditCropYearPage = () => (
  <CropYearDataWrapper>
    <EditCropYearForm />
  </CropYearDataWrapper>
);

export default EditCropYearPage;
