import { ReactElement, ReactNode } from "react";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import {
  GetNextPageParamFunction,
  GetPreviousPageParamFunction,
} from "react-query/types/core/types";

import { BaseApiError, PaginationMeta } from "services/apiHelpers";
import { clearAuthData } from "utils/authUtils";

const getNextPage: GetNextPageParamFunction<{
  meta: PaginationMeta;
}> = ({ meta: { page = 0, lastPage = 0 } }) =>
  page + 1 < lastPage ? page + 1 : undefined;
const getPrevPage: GetPreviousPageParamFunction<{
  meta: PaginationMeta;
}> = ({ meta: { page = 0 } }) => (page > 0 ? page - 1 : undefined);

const invalidAPIResponse = (status?: number) => {
  return status && status === 401;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, // 5 minutes to match cache time
      getNextPageParam: getNextPage as GetNextPageParamFunction,
      getPreviousPageParam: getPrevPage as GetPreviousPageParamFunction,
      retry: 1,
    },
  },
  queryCache: new QueryCache({
    onError: async function (error) {
      const { response } = error as BaseApiError;
      if (invalidAPIResponse(response?.status)) {
        // Invalidate auth data
        clearAuthData(true);
        window.location.reload();
      }
    },
  }),
});

const ReactQueryProvider = ({
  children,
  queryClient: _queryClient,
}: {
  children: ReactNode;
  queryClient?: QueryClient;
}): ReactElement => (
  <QueryClientProvider client={_queryClient ?? queryClient}>
    {children}
  </QueryClientProvider>
);

export default ReactQueryProvider;
