import { useQuery } from "react-query";
import { useFieldService } from "contexts/ServiceProvider";

const useGetFieldCsvGuideRequirements = () => {
  const fieldService = useFieldService();

  return useQuery({
    queryKey: ["field-csv-upload-guide-requirements"],
    queryFn: () => fieldService.getFieldCsvUploadGuideRequirements(),
  });
};

export default useGetFieldCsvGuideRequirements;
