import { MinimumFarmListDto } from "api";
import { useCropYearService } from "contexts/ServiceProvider";
import { useQuery } from "react-query";
import { BaseApiError } from "services/apiHelpers";

export const GET_CROP_YEAR_ADDABLE_FARMS_QUERY_KEY = "crop-year-addable-farms";

const useAddableFarms = (cropYearId?: string) => {
  const cropYearService = useCropYearService();

  return useQuery<MinimumFarmListDto[], BaseApiError>({
    queryKey: [GET_CROP_YEAR_ADDABLE_FARMS_QUERY_KEY, cropYearId],
    queryFn: () =>
      cropYearId
        ? cropYearService.getAddableFarms({ id: cropYearId })
        : Promise.reject("Must have crop year id"),
  });
};

export default useAddableFarms;
