import { useInfiniteQuery } from "react-query";

import { HaylageYearFarmResponse } from "api";
import { useHaylageYearService } from "contexts/ServiceProvider";
import { BaseApiError, PaginationMeta, QueryParams } from "services/apiHelpers";
import { FarmsSortColumns } from "components/FarmListTable/listTableDataMapper";

export type GetHaylageYearFarmsResponse = {
  haylageYearFarms: HaylageYearFarmResponse[];
  meta: PaginationMeta;
};

export const HAYLAGE_YEAR_FARMS_QUERY_KEY = "list-haylage-year-farms";

const useGetHaylageYearFarms = (
  haylageYearId?: string,
  queryParams?: Omit<QueryParams<FarmsSortColumns>, "page">
) => {
  const haylageYearService = useHaylageYearService();

  return useInfiniteQuery<GetHaylageYearFarmsResponse, BaseApiError>(
    [HAYLAGE_YEAR_FARMS_QUERY_KEY, haylageYearId, queryParams],
    ({ pageParam = 0 }) =>
      haylageYearId
        ? haylageYearService
            .getHaylageYearFarms(haylageYearId, {
              ...queryParams,
              page: pageParam,
              size: 10,
            })
            .then(({ data, meta }) => ({
              haylageYearFarms: data,
              meta,
            }))
        : Promise.reject(new Error("haylageYearId was not provided.")),
    { enabled: !!haylageYearId }
  );
};

export default useGetHaylageYearFarms;
