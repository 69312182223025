import { useCallback, useMemo } from "react";

import {
  CSV_FILE_SIZE_REQUIREMENT,
  CSV_FILE_TYPE_REQUIREMENT,
} from "constants/csvRequirements";
import useGetFieldCsvGuideRequirements from "hooks/csv-upload/useGetFieldCsvGuideRequirements";
import useUploadFieldsCsv from "hooks/csv-upload/useUploadFieldCsv";
import CsvFileSelect from "pages/csv-uploads/CsvFileSelect";
import WithSpinner from "components/WithSpinner";

const CSV_UPLOAD_OVERWRITE_FIELDS_WARNING_REQUIREMENT =
  "This upload may overwrite existing Field Information";

const fieldsCsvFileRequirements = [
  { message: CSV_FILE_TYPE_REQUIREMENT },
  { message: CSV_FILE_SIZE_REQUIREMENT },
];

const FieldsFileSelect = () => {
  const { mutateAsync, isLoading } = useUploadFieldsCsv();
  const { data, isLoading: isFieldCsvGuideLoading } =
    useGetFieldCsvGuideRequirements();

  const onUploadCsvFile = useCallback(
    (file: File) => mutateAsync({ csvFile: file }),
    [mutateAsync]
  );

  const fieldsAllCsvFileRequirements = useMemo(() => {
    const fieldCsvRequiredColumnsRequirements = `File must contain, in order: ${data
      ?.slice(0, data?.length - 1)
      .join(", ")}, and ${data?.[data?.length - 1]}`;

    return [
      ...fieldsCsvFileRequirements,
      { message: fieldCsvRequiredColumnsRequirements },
      { message: CSV_UPLOAD_OVERWRITE_FIELDS_WARNING_REQUIREMENT },
    ];
  }, [data]);

  return (
    <WithSpinner
      isLoading={isFieldCsvGuideLoading}
      centerProps={{ minH: "600px" }}
    >
      <CsvFileSelect
        fileRequirements={fieldsAllCsvFileRequirements}
        isLoading={isLoading}
        onUploadCsvFile={onUploadCsvFile}
      />
    </WithSpinner>
  );
};

export default FieldsFileSelect;
