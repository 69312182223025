import {
  HaylageYearApiListHaylageYearsRequest,
  HaylageYearResponse,
} from "api";
import { useHaylageYearService } from "contexts/ServiceProvider";
import { useQuery } from "react-query";
import { BaseApiError, PaginationMeta } from "services/apiHelpers";
import { GetHaylageYearResponse } from "services/HaylageYearService";

export const GET_HAYLAGE_YEARS_QUERY_KEY = "haylage-years";

export type HaylageYearListItem = {
  id: string;
  year: number;
};

export type GetHaylageYearsListItemResponse = {
  haylageYears: HaylageYearListItem[];
  meta: PaginationMeta;
};

export const transformHaylageYearResponse = (
  haylageYear: HaylageYearResponse
): HaylageYearListItem => ({
  id: haylageYear.id,
  year: haylageYear.year,
});

const useGetHaylageYears = (
  enabled = true,
  queryParams?: HaylageYearApiListHaylageYearsRequest
) => {
  const haylageYearService = useHaylageYearService();

  return useQuery<
    GetHaylageYearResponse,
    BaseApiError,
    GetHaylageYearsListItemResponse
  >({
    queryKey: [
      GET_HAYLAGE_YEARS_QUERY_KEY,
      queryParams,
      { page: queryParams?.page },
    ],
    queryFn: () =>
      haylageYearService.getHaylageYears({
        ...queryParams,
        page: queryParams?.page ?? 0,
      }),
    select: ({ data, meta }) => ({
      haylageYears: data.map(transformHaylageYearResponse),
      meta,
    }),
    enabled,
  });
};

export default useGetHaylageYears;
