import { useInfiniteQuery } from "react-query";
import { useCropYearService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";
import { FarmDocumentPage } from "../../types/common/farmDocumentPage";

export const SILAGE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY =
  "silage-year-farm-document-link";

const useSilageYearFarmDocuments = (
  silageYearId: string | undefined,
  farmId: string | undefined
) => {
  const cropYearService = useCropYearService();

  return useInfiniteQuery<FarmDocumentPage, BaseApiError>(
    [SILAGE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY, { silageYearId, farmId }],
    ({ pageParam = 0 }) =>
      farmId && silageYearId
        ? cropYearService.getSilageYearFarmDocuments(silageYearId, farmId, {
            page: pageParam,
            size: 10,
          })
        : Promise.reject(new Error("Missing silageYearId or farmId")),
    { enabled: !!silageYearId || !!farmId }
  );
};

export default useSilageYearFarmDocuments;
