import { Navigate, useParams } from "react-router-dom";

import FormSkeletonLoadingState from "components/FormSkeletonLoadingState";
import RedirectToDashboard from "components/redirects/RedirectToDashboard";
import EditForwardPricingForm from "forms/forward-pricing/EditForwardPricingForm";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useFarmCropYearSummary from "hooks/crop-year/useFarmCropYearSummary";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import useGetPricingOptionDetails from "hooks/pricing-option/useGetPricingOptionDetails";
import useSelectedCropYearId from "hooks/useSelectedCropYearId";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import dashboardPaths from "routes/dashboard/dashboardPaths";

const EditForwardPricingPage = () => {
  const { id: pricingOptionId } = useParams();
  const { isEmployee } = useUserRoleFlags();
  const [selectedCropYearId] = useSelectedCropYearId();
  const [selectedFarmId] = useSelectedFarmId();

  const { data: pricingOption, isLoading: isLoadingPricingOptionDetails } =
    useGetPricingOptionDetails(pricingOptionId, selectedFarmId);
  const { data: cropYearDetailData, isLoading: isLoadingCropYearDetails } =
    useCropYearDetail(selectedCropYearId);
  const { data: farmDetailData, isLoading: isLoadingFarmDetails } =
    useFarmDetail(selectedFarmId);
  const { data: farmCropYearSummary, isLoading: isFarmCropYearSummaryLoading } =
    useFarmCropYearSummary(selectedCropYearId, selectedFarmId);

  if (
    isLoadingPricingOptionDetails ||
    isLoadingCropYearDetails ||
    isLoadingFarmDetails ||
    isFarmCropYearSummaryLoading
  ) {
    return <FormSkeletonLoadingState rowCount={10} />;
  }

  if (
    !pricingOption ||
    !cropYearDetailData ||
    !farmDetailData ||
    !farmCropYearSummary
  ) {
    return isEmployee ? (
      <Navigate
        to={`${dashboardPaths.basePath}/${dashboardPaths.children.pricing}`}
      />
    ) : (
      <RedirectToDashboard />
    );
  }

  return (
    <EditForwardPricingForm
      pricingOption={pricingOption}
      cropYear={cropYearDetailData}
      farm={farmDetailData}
      farmCropYearSummary={farmCropYearSummary}
    />
  );
};

export default EditForwardPricingPage;
