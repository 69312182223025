import { AxiosError, AxiosResponse } from "axios";
import { Meta, NotificationDto, ProfilePictureResponse } from "api";
import { ApiError } from "./ApiError";

export type BaseApiError = AxiosError<{ error: string }>;

export type PaginationMeta = {
  page?: number;
  size?: number;
  totalPages?: number;
  lastPage?: number;
};
import { Sort, SortDirection } from "hooks/useSort";

const transformApiMeta = (meta?: Meta): PaginationMeta => ({
  page: meta?.currentPage,
  size: meta?.perPage,
  totalPages: meta?.total,
  lastPage: meta?.lastPage,
});

// Use this if the response is a string (usually this means the BE didn't specify a response).
export const processResponse = <
  T extends string | { data: unknown; meta?: Meta }
>(
  response: AxiosResponse<T>
): AxiosResponse<T>["data"] => {
  if (response.status < 200 || response.status >= 400) {
    throw new ApiError(response.status, response.statusText, response.data);
  }

  return response.data;
};
// Use this if the response has a data property.
export const extractResponseData = <T extends { data: unknown }>(
  response: AxiosResponse<T>
): T["data"] => {
  const { data } = processResponse(response);

  return data;
};
// Use this if the response is a collection. It will have both a data and a meta property.
export const extractResponseDataWithMeta = <
  T extends { data: unknown; meta: Meta }
>(
  response: AxiosResponse<T>
): { data: T["data"]; meta: PaginationMeta } => {
  const { data, meta } = processResponse(response);

  return {
    data,
    meta: transformApiMeta(meta),
  };
};

export const extractResponseErrorMessage = <T extends { error: string }>(
  response: AxiosResponse<T> | undefined
): string => response?.data?.error ?? "";

export type QueryParams<SortKeys = unknown> = {
  page?: number;
  search?: string;
  sort?: Sort<SortKeys>[];
  size?: number;
};

export const transformQueryParamsForRequest = (
  queryParams?: QueryParams
): Omit<QueryParams, "sort"> & {
  sort: string[] | undefined;
} => ({
  page: queryParams?.page ?? 0,
  size: queryParams?.size,
  sort: queryParams?.sort
    ?.map(({ key, direction }) =>
      direction !== SortDirection.NEUTRAL ? `${key},${direction}` : undefined
    )
    .filter((sort) => !!sort) as string[],
  search: queryParams?.search,
});

export const transformProfilePictureResponse = (
  profilePictureResponse?: ProfilePictureResponse
) => ({
  src: profilePictureResponse?.url,
  xOffset: profilePictureResponse?.xoffset ?? 0,
  yOffset: profilePictureResponse?.yoffset ?? 0,
});

export const transformNotificationResponse = (
  notificationResponse: NotificationDto
) => ({ ...notificationResponse, data: JSON.parse(notificationResponse.data) });
