import { ReactElement } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@chakra-ui/react";

import { SelectInput } from "components/inputs";
import SubmitButton from "components/SubmitButton";
import { useCropYearContext } from "contexts/CropYearContext";
import useAddableFarms from "hooks/crop-year/useAddableFarms";
import useCustomToast from "hooks/useCustomToast";
import useFormValidation from "hooks/useFormValidation";
import useAssignFarmToCropYear from "hooks/crop-year/useAssignFarmToCropYear";
import { addFarmSchema } from "./addFarmSchema";

type AddFarm = {
  farm: {
    label: string;
    value: string;
  };
};

const AddFarmForm = ({ onClose }: { onClose: () => void }): ReactElement => {
  const { cropYear } = useCropYearContext();
  const {
    data,
    isLoading,
    error: addableFarmsError,
    isError: addableFarmsIsError,
  } = useAddableFarms(cropYear?.id);
  const { onSubmitAsync, isLoading: assignFarmIsLoading } =
    useAssignFarmToCropYear();

  const methods = useForm<AddFarm>({
    resolver: yupResolver(addFarmSchema),
    defaultValues: {
      farm: undefined,
    },
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const { onSuccessToast, onErrorToast } = useCustomToast();

  const requiredFieldsNotFilled = useFormValidation(watch());

  return (
    <FormProvider {...methods}>
      <Stack
        as="form"
        onSubmit={handleSubmit(async (data) => {
          await onSubmitAsync({
            cropYearId: cropYear?.id,
            farmId: data.farm.value,
          })
            .then(() => {
              onSuccessToast({ message: "Added farm to the crop year" });
              onClose();
            })
            .catch(() => {
              onErrorToast({
                message: "Failed to add farm to the crop year",
              });
              onClose();
            });
        })}
      >
        <SelectInput
          id="farm"
          label="Farm"
          options={data?.map((addableFarm) => ({
            value: addableFarm.id,
            label: addableFarm.doingBusinessAs,
          }))}
          isLoading={isLoading}
          errorMsg={addableFarmsError?.message}
          isError={addableFarmsIsError}
        />
        <SubmitButton
          submitDisabled={requiredFieldsNotFilled}
          isSubmitting={isSubmitting || assignFarmIsLoading}
          buttonText="Activate Farm"
        />
      </Stack>
    </FormProvider>
  );
};

export default AddFarmForm;
