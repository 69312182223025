import { useMutation } from "react-query";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { createFileDownload } from "utils/createFileDownload";
import { CropYearListItem } from "hooks/crop-year/useCropYears";
import { formatDate } from "utils/dateUtils";

const DOWNLOAD_PRICING_DATA_CSV_KEY = "download-pricing-data-csv";

const useDownloadPricingData = ({
  cropYear = { id: "", year: 2023 },
}: {
  cropYear: CropYearListItem | undefined;
}) => {
  const pricingOptionService = usePricingOptionService();
  const cropYearId = cropYear?.id;
  const formattedDateOfDownload = formatDate(new Date());

  return useMutation({
    mutationKey: [DOWNLOAD_PRICING_DATA_CSV_KEY],
    mutationFn: () => pricingOptionService.downloadPricingData({ cropYearId }),
    onSuccess: (data: Blob) =>
      createFileDownload(
        data,
        `${cropYear.year}_Pricing_Data_${formattedDateOfDownload}.csv`
      ),
  });
};

export default useDownloadPricingData;
