import { useMutation, useQueryClient } from "react-query";

import { useCropYearService } from "contexts/ServiceProvider";
import CropYearService from "services/CropYearService";
import { MutationHookResult } from "../hookTypeHelpers";
import { GET_CROP_YEAR_DETAIL_QUERY_KEY } from "./useCropYearDetails";
import { GET_CROP_YEARS_QUERY_KEY } from "hooks/crop-year/useCropYears";
import useDashboardRefetchQueries from "./useDashboardRefetchQueries";

export const TRIGGER_POST_HARVEST_QUERY_KEY = "crop-year-trigger-post-harvest";

type TriggerPostHarvestMutationResult = MutationHookResult<
  Awaited<ReturnType<CropYearService["triggerPostHarvest"]>>
>;

const useTriggerPostHarvest = (
  cropYearId?: string
): TriggerPostHarvestMutationResult => {
  const cropYearService = useCropYearService();
  const queryClient = useQueryClient();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationKey: [TRIGGER_POST_HARVEST_QUERY_KEY, { id: cropYearId }],
    mutationFn: () =>
      cropYearId
        ? cropYearService.triggerPostHarvest(cropYearId)
        : Promise.reject("Must provide crop year id"),
    onSuccess: () =>
      Promise.all([
        queryClient.refetchQueries([GET_CROP_YEAR_DETAIL_QUERY_KEY]),
        queryClient.refetchQueries([GET_CROP_YEARS_QUERY_KEY]),
        refetch(),
      ]),
  });
};

export default useTriggerPostHarvest;
