import { useMutation } from "react-query";

import { CreateFieldRequest } from "api";
import { useFieldService } from "contexts/ServiceProvider";
import FieldService from "services/FieldService";
import { MutationHookResult } from "../hookTypeHelpers";
import useRefetchFieldQueries from "./useRefetchFieldQueries";

type CreateFieldMutationResult = MutationHookResult<
  Awaited<ReturnType<FieldService["createField"]>>,
  CreateFieldRequest
>;

export const CREATE_FIELD_QUERY_KEY = "create-field";

const useCreateField = (): CreateFieldMutationResult => {
  const fieldService = useFieldService();
  const { refetch } = useRefetchFieldQueries();

  return useMutation({
    mutationFn: (fieldInfo) => {
      return fieldInfo
        ? fieldService.createField(fieldInfo)
        : Promise.reject("Must provide valid field information");
    },
    mutationKey: [CREATE_FIELD_QUERY_KEY],
    onSuccess: () => refetch(),
  });
};

export default useCreateField;
