import { useMutation } from "react-query";

import { UpdateDelayedContractRequest } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import useDashboardRefetchQueries from "hooks/crop-year/useDashboardRefetchQueries";
import { MutationHookResult } from "hooks/hookTypeHelpers";
import PricingOptionService from "services/PricingOptionService";

type UpdateDelayedPricingContractMutationResult = MutationHookResult<
  Awaited<ReturnType<PricingOptionService["updateDelayedPricingContract"]>>,
  UpdateDelayedContractRequest
>;

export const UPDATE_DELAYED_PRICING_CONTRACT_MUTATION_KEY =
  "update-delayed-pricing-contract";

const useUpdateDelayedPricingContract = (
  pricingOptionId: string | undefined,
  cropYearId: string | undefined,
  farmId: string | undefined
): UpdateDelayedPricingContractMutationResult => {
  const pricingOptionService = usePricingOptionService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationFn: (updateDelayedContractRequest) => {
      if (cropYearId && farmId && pricingOptionId) {
        return updateDelayedContractRequest
          ? pricingOptionService.updateDelayedPricingContract({
              pricingOptionId,
              cropYearId,
              farmId,
              updateDelayedContractRequest,
            })
          : Promise.reject(
              "Must provide valid average pricing contract information"
            );
      } else {
        return Promise.reject(
          "Missing crop year ID, delayed pricing contract ID, or farm ID"
        );
      }
    },
    mutationKey: [UPDATE_DELAYED_PRICING_CONTRACT_MUTATION_KEY],
    onSuccess: refetch,
  });
};

export default useUpdateDelayedPricingContract;
