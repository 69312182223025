import { MinimumFarmListDto } from "api";
import { useFarmService } from "contexts/ServiceProvider";
import { useQuery } from "react-query";
import { BaseApiError, PaginationMeta } from "services/apiHelpers";

export type GetFarmListByCropYearResponse = {
  farmList: MinimumFarmListDto[];
  meta: PaginationMeta;
};

export const GET_FARMS_IN_CROP_YEAR_QUERY_KEY = "get-farms-in-crop-year";

const useGetFarmListByCropYear = (cropYearId?: string) => {
  const farmService = useFarmService();

  return useQuery<GetFarmListByCropYearResponse, BaseApiError>({
    queryKey: [GET_FARMS_IN_CROP_YEAR_QUERY_KEY, { cropYearId }],
    queryFn: ({ pageParam = 0 }) => {
      return cropYearId
        ? farmService
            .getFarmsInCropYear(cropYearId, {
              page: pageParam,
              size: 1000,
            })
            .then(({ data, meta }) => ({
              farmList: data,
              meta,
            }))
        : Promise.reject(new Error("Must have crop year id"));
    },
  });
};

export default useGetFarmListByCropYear;
