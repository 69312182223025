import { useQuery } from "react-query";

import { DairyDto } from "api";
import { useDairyService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";

export const GET_DAIRIES_QUERY_KEY = "get-dairies";

const useGetDairies = (regionId?: string) => {
  const dairyService = useDairyService();

  return useQuery<DairyDto[], BaseApiError>({
    queryKey: [GET_DAIRIES_QUERY_KEY, { regionId }],
    queryFn: () =>
      regionId
        ? dairyService.getDairies(regionId)
        : Promise.reject("Missing region ID"),
    retry: 1,
    enabled: !!regionId,
  });
};

export default useGetDairies;
