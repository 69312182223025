import { useInfiniteQuery } from "react-query";

import { FarmListDto, FarmStatusDto, RegionDto } from "api";
import { useFarmService } from "contexts/ServiceProvider";
import {
  BaseApiError,
  PaginationMeta,
  QueryParams,
  transformProfilePictureResponse,
} from "services/apiHelpers";
import { FarmListSortColumns } from "services/FarmService";

type GetFarmsListResponse = { farms: FarmListItem[]; meta: PaginationMeta };

export type FarmListItem = {
  id: string;
  farm: {
    name: string;
    phoneNumber?: string;
    entityId?: number;
  };
  mainContact: {
    name: string;
    phoneNumber?: string;
    profilePicture?: ProfilePicture;
  };
  region: RegionDto | undefined;
  status: FarmStatusDto;
};

export const GET_FARMS_QUERY_KEY = "get-farms";

export const transformFarmListResponse = (farm: FarmListDto): FarmListItem => {
  return {
    id: farm.id,
    farm: {
      name: farm.farm.name,
      phoneNumber: farm.farm.phoneNumber,
      entityId: farm.farm.entityId,
    },

    mainContact: {
      name: `${farm.mainContact?.firstName} ${farm.mainContact?.lastName}`,
      phoneNumber: farm.mainContact?.phoneNumber,
      profilePicture: transformProfilePictureResponse(
        farm?.mainContact?.profilePicture
      ),
    },
    region: farm.region,
    status: farm.status,
  };
};

const useFarmsList = (
  queryParams?: Omit<QueryParams<FarmListSortColumns>, "page">
) => {
  const farmService = useFarmService();

  return useInfiniteQuery<GetFarmsListResponse, BaseApiError>(
    [GET_FARMS_QUERY_KEY, queryParams],
    ({ pageParam = 0 }) =>
      farmService
        .getFarms({ ...queryParams, page: pageParam })
        .then(({ data, meta }) => ({
          farms: data.map(transformFarmListResponse),
          meta,
        }))
  );
};

export default useFarmsList;
