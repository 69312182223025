import { useMutation } from "react-query";

import { CreateFarmRequest } from "api";
import { useFarmService } from "contexts/ServiceProvider";
import { MutationHook, MutationHookResult } from "hooks/hookTypeHelpers";
import FarmService from "services/FarmService";

type CreateFarmMutationResult = MutationHookResult<
  Awaited<ReturnType<FarmService["createFarm"]>>,
  CreateFarmRequest
>;

const useCreateFarmMutation = (): CreateFarmMutationResult => {
  const farmService = useFarmService();

  return useMutation({
    mutationKey: ["create-farm"],
    mutationFn: (farmInfo) => {
      const allRequired = Object.entries(farmInfo).every(
        ([, value]) => !!value
      );
      if (!allRequired) {
        throw new Error("Missing farm info");
      }

      return farmService.createFarm(farmInfo);
    },
  });
};

const useCreateFarm = (): MutationHook<
  CreateFarmMutationResult,
  "onSubmit"
> => {
  const {
    mutate: onSubmit,
    mutateAsync: onSubmitAsync,
    ...rest
  } = useCreateFarmMutation();

  return { onSubmit, onSubmitAsync, ...rest };
};

export default useCreateFarm;
