import { useQuery } from "react-query";

import { HaylageYearResponse } from "api/models/haylage-year-response";
import { useHaylageYearService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";

export const GET_HAYLAGE_YEAR_DETAIL_QUERY_KEY = "haylage-year-detail";

const useGetHaylageYear = (haylageYearId?: string) => {
  const haylageYearService = useHaylageYearService();

  return useQuery<HaylageYearResponse | undefined, BaseApiError>({
    queryKey: [GET_HAYLAGE_YEAR_DETAIL_QUERY_KEY, haylageYearId],
    queryFn: () =>
      haylageYearId
        ? haylageYearService.getHaylageYear({ id: haylageYearId })
        : Promise.reject(new Error("haylageYearId is undefined")),
    enabled: !!haylageYearId,
  });
};

export default useGetHaylageYear;
