import { useQuery } from "react-query";
import { useUserService } from "contexts/ServiceProvider";
import UserService from "services/UserService";
import { BaseApiError } from "services/apiHelpers";

type GetInviteDetailsResponse = Awaited<ReturnType<UserService["getInvite"]>>;

const useInviteDetails = (
  token: string | undefined | null,
  onSuccess: (data: GetInviteDetailsResponse) => void,
  onError?: (error: BaseApiError) => void
) => {
  const userService = useUserService();

  return useQuery<GetInviteDetailsResponse, BaseApiError>({
    queryKey: ["invite-details", token],
    queryFn: () =>
      token
        ? userService.getInvite(token)
        : Promise.reject("Token must be provided"),
    retry: 0,
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

export default useInviteDetails;
