import { useInfiniteQuery } from "react-query";

import { useFarmService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";
import { FarmDocumentPage } from "types/common/farmDocumentPage";

export const FARM_DOCUMENT_LINK_QUERY_KEY = "farm-document-link";

const useFarmDocumentList = (farmId: string | undefined) => {
  const farmService = useFarmService();

  return useInfiniteQuery<FarmDocumentPage, BaseApiError>({
    queryKey: [FARM_DOCUMENT_LINK_QUERY_KEY, { farmId }],
    queryFn: ({ pageParam = 0 }) =>
      farmId
        ? farmService.getFarmDocumentsPage(farmId, {
            page: pageParam,
            size: 10,
          })
        : Promise.reject(new Error("Missing farmId")),
    enabled: !!farmId,
    cacheTime: 0,
  });
};

export default useFarmDocumentList;
