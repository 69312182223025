import { useMutation } from "react-query";

import { usePricingOptionService } from "contexts/ServiceProvider";
import useDashboardRefetchQueries from "hooks/crop-year/useDashboardRefetchQueries";

const DELETE_FORWARD_CONTRACT_MUTATION_KEY = "delete-forward-contract";

const useDeleteForwardContracts = () => {
  const pricingOptionService = usePricingOptionService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationKey: [DELETE_FORWARD_CONTRACT_MUTATION_KEY],
    mutationFn: ({
      cropYearId,
      pricingOptionIds,
    }: {
      cropYearId?: string;
      pricingOptionIds: string[];
    }) => {
      if (!cropYearId) {
        throw new Error("Missing cropYearId");
      }

      return pricingOptionService.deleteFarmCropYearPricingOptions({
        cropYearId,
        pricingOptionIds,
      });
    },
    onSuccess: () => refetch(),
  });
};

export default useDeleteForwardContracts;
