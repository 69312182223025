import { useQuery } from "react-query";

import { NewCropFuturesDto } from "api";
import { useFuturesService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";

export const GET_NEW_CROP_FUTURES_QUERY_KEY = "get-new-crop-futures";

export const useGetNewCropFutures = () => {
  const futuresService = useFuturesService();

  return useQuery<NewCropFuturesDto, BaseApiError>({
    queryKey: [GET_NEW_CROP_FUTURES_QUERY_KEY],
    queryFn: () => futuresService.getNewCropFutures(),
  });
};

export default useGetNewCropFutures;
