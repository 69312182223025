import { CropYearSummaryDto } from "api";
import { useCropYearService } from "contexts/ServiceProvider";
import { useQuery } from "react-query";
import { BaseApiError } from "services/apiHelpers";

export const GET_CROP_YEAR_SUMMARY_QUERY_KEY = "crop-year-summary";

const useCropYearSummary = (cropYearId?: string) => {
  const cropYearService = useCropYearService();

  return useQuery<CropYearSummaryDto | undefined, BaseApiError>({
    queryKey: [GET_CROP_YEAR_SUMMARY_QUERY_KEY, { cropYearId }],
    queryFn: () =>
      cropYearId
        ? cropYearService.getCropYearSummary(cropYearId)
        : Promise.reject("Missing crop year ID"),
    enabled: !!cropYearId,
    cacheTime: 0,
  });
};

export default useCropYearSummary;
