import { useCallback, useMemo } from "react";
import { VStack, Button, Text, Divider } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  CreateForwardContractRequest,
  CropYearDetailResponse,
  CropYearFarmSummaryDto,
  CropYearRegionDto,
} from "api";
import { PaymentDate, PercentageToSellInput } from "./components";
import { FormInputs, MoneyInput } from "components/inputs";
import { DEFER_PAYMENT_OPTION_YES } from "components/inputs/DeferPaymentSelectInput";
import { DeferPaymentInputs } from "components/inputs/DeferPaymentInputs";
import { LabelTextWithHelper } from "components/inputs/InputLabel";
import { BasePrice, Multiplier } from "components/PricingStats/PricingStats";
import { SelectOption } from "components/Select";
import SubmitButton from "components/SubmitButton";
import { DIALOG_OFFSET } from "forms/delayed-pricing/DelayedPricingForm";
import { manualForwardContractSchema } from "forms/pricing/manualForwardContractSchema";
import useConfirmationAlert from "hooks/alerts/useConfirmationAlert";
import useCreateForwardContract from "hooks/pricing-option/useCreateForwardContract";
import useCustomToast from "hooks/useCustomToast";
import useFormValidation from "hooks/useFormValidation";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import { CALCULATED_AT_PRE_HARVEST_MESSAGE } from "./NewForwardPricingContractForm";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { ISODateWithoutTime, parseDate } from "utils/dateUtils";
import { getIsDeferPayment } from "utils/getIsDeferPayment";
import DateInput from "components/inputs/DateInput";

export type ManualForwardPricingContractFormData = {
  percentageToSell: number;
  cashPrice: number;
  deferPayment: SelectOption;
  defermentDate?: Date;
  dateOfContractCreation: Date;
  isManuallyCreated: boolean;
};

const transformManualForwardPricingData = (
  data: ManualForwardPricingContractFormData,
  deferPayment: boolean,
  isManuallyCreated: boolean
): CreateForwardContractRequest => ({
  ...data,
  deferPayment,
  defermentDate:
    deferPayment && data.defermentDate
      ? ISODateWithoutTime(data.defermentDate)
      : undefined,
  dateOfContractCreation: ISODateWithoutTime(data.dateOfContractCreation),
  isManuallyCreated,
});

const NewManualForwardPricingContractForm = ({
  regionDetails,
  farmCropYearSummary,
  cropYearDetail,
  cropYearId,
  farmId,
}: {
  regionDetails: CropYearRegionDto | undefined;
  farmCropYearSummary: CropYearFarmSummaryDto | undefined;
  cropYearDetail: CropYearDetailResponse | undefined;
  cropYearId: string | undefined;
  farmId: string | undefined;
}) => {
  const navigate = useNavigateWithQuery();
  const navigateToFarmView = useCallback(
    () =>
      navigate(
        `/${dashboardPaths.basePath}/${dashboardPaths.children.pricing}`
      ),
    [navigate]
  );
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const [selectedFarmId] = useSelectedFarmId();
  const { data: farmDetails } = useFarmDetail(selectedFarmId);

  const remainingPercent = useMemo(
    () => 100 - (farmCropYearSummary?.percentageOfTonsPriced ?? 0),
    [farmCropYearSummary?.percentageOfTonsPriced]
  );

  const multiplier = useMemo(
    () => regionDetails?.multiplier ?? 0,
    [regionDetails?.multiplier]
  );

  const {
    mutateAsync: onCreateForwardContract,
    isLoading: isCreateForwardContractLoading,
  } = useCreateForwardContract(cropYearId, farmId, true);
  const defaultDefermentDate = useMemo(
    () =>
      cropYearDetail?.defermentDateStart
        ? parseDate(cropYearDetail.defermentDateStart)
        : new Date(),
    [cropYearDetail?.defermentDateStart]
  );
  const methods = useForm<ManualForwardPricingContractFormData>({
    resolver: yupResolver(
      manualForwardContractSchema(defaultDefermentDate, remainingPercent)
    ),
    defaultValues: {
      dateOfContractCreation: new Date(),
      percentageToSell: 0,
      deferPayment: DEFER_PAYMENT_OPTION_YES,
      defermentDate: defaultDefermentDate,
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting },
  } = methods;

  const cashPrice = watch("cashPrice");

  const requiredFieldsNotFilled = useFormValidation(watch());

  const {
    alert: confirmAlert,
    onOpen: onConfirm,
    onClose,
    isOpen: confirmAlertIsOpen,
  } = useConfirmationAlert({
    title: "Are you sure you want to create this contract?",
    msg: "",
    confirmButtonText: `Yes`,
    onConfirmAsync: () => {
      const formData = watch();
      const data = transformManualForwardPricingData(
        formData,
        getIsDeferPayment(formData.deferPayment.value),
        true
      );

      return onCreateForwardContract(data)
        .then(() => {
          navigate(
            `${dashboardPaths.basePath}/${dashboardPaths.children.pricing}`
          );
          onSuccessToast({
            message: "Contract successfully created",
          });
        })
        .catch(() => {
          onErrorToast({
            message: `Failed to create forward pricing contract`,
          });
          onClose();
        });
    },
    dialogOffset: DIALOG_OFFSET,
  });

  const calculateBasePrice = useMemo(
    () => (cashPrice === undefined ? 0 : multiplier * cashPrice),
    [cashPrice, multiplier]
  );

  const isDeferPayment = getIsDeferPayment(watch("deferPayment").value);

  return (
    <VStack align="left" w="100%">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onConfirm)}>
          {confirmAlert}
          <VStack align="left" spacing={4}>
            <VStack align="left" spacing={4}>
              <FormInputs
                inputs={[
                  {
                    id: "dateOfContractCreation",
                    label: "Date of Contract",
                    component: DateInput,
                    shouldRegister: false,
                    isRequired: true,
                  },
                ]}
              />
              <PercentageToSellInput />
              <DeferPaymentInputs
                isDeferPayment={isDeferPayment}
                minDate={defaultDefermentDate}
              />
              {!isDeferPayment &&
                !!cropYearDetail?.firstPaymentDateWithoutDeferral && (
                  <PaymentDate
                    firstPaymentDateWithoutDeferral={parseDate(
                      cropYearDetail.firstPaymentDateWithoutDeferral
                    )}
                  />
                )}
              <MoneyInput
                id="cashPrice"
                label={
                  <LabelTextWithHelper
                    label="Cash Price"
                    helper={`${farmDetails?.region?.name} Region`}
                  />
                }
                defaultEmptyValueToZero={false}
                isRequired
              />
              <Multiplier multiplier={multiplier} />
              <BasePrice basePrice={calculateBasePrice} />
            </VStack>
            <Divider color="grayFactor.2" opacity={1} borderWidth="1px" />
            <Text>{CALCULATED_AT_PRE_HARVEST_MESSAGE}</Text>
            <VStack w="100%">
              <SubmitButton
                submitDisabled={
                  requiredFieldsNotFilled || !isDirty || confirmAlertIsOpen
                }
                isSubmitting={isSubmitting || isCreateForwardContractLoading}
                buttonText="Submit"
                w="100%"
              />

              <Button
                variant="outline"
                size="md"
                onClick={navigateToFarmView}
                w="100%"
              >
                Cancel
              </Button>
            </VStack>
          </VStack>
        </form>
      </FormProvider>
    </VStack>
  );
};

export default NewManualForwardPricingContractForm;
