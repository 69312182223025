import { useQuery } from "react-query";
import { useUserService } from "contexts/ServiceProvider";
import { UserResponse } from "api";
import { BaseApiError } from "services/apiHelpers";

export const GET_USER_DETAILS_QUERY_KEY = "get-user-details";

const useUserDetails = (id?: string, farmId?: string) => {
  const userService = useUserService();

  return useQuery<UserResponse, BaseApiError>({
    queryKey: [GET_USER_DETAILS_QUERY_KEY, { id, farmId }],
    queryFn: () =>
      id
        ? userService.getUserDetails(id, farmId)
        : Promise.reject("Must have a user ID"),
    enabled: !!id,
  });
};

export default useUserDetails;
