import { useCallback } from "react";
import { VStack, Button, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { QueryObserverResult } from "react-query";

import { PaymentDate, PercentageToSellInput } from "./components";
import {
  CreateForwardContractRequest,
  CropYearDetailResponse,
  ForwardContractCurrentPricingResponse,
} from "api";
import FuturesPriceTimeoutError from "components/FuturesPriceTimeoutError";
import { DeferPaymentInputs } from "components/inputs/DeferPaymentInputs";
import { DEFER_PAYMENT_OPTION_YES } from "components/inputs/DeferPaymentSelectInput";
import { SelectOption } from "components/Select";
import SubmitButton from "components/SubmitButton";
import { DIALOG_OFFSET } from "forms/delayed-pricing/DelayedPricingForm";
import { baseForwardContractSchema } from "forms/schemas/baseForwardContractSchema";
import useConfirmationAlert from "hooks/alerts/useConfirmationAlert";
import useCreateForwardContract from "hooks/pricing-option/useCreateForwardContract";
import useCustomToast from "hooks/useCustomToast";
import useFormValidation from "hooks/useFormValidation";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import { useScrollIntoView } from "hooks/utility/useScrollIntoView";
import ForwardPricingContractStats from "pages/pricing/ForwardPricing/ForwardPricingContractStats";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import { BaseApiError } from "services/apiHelpers";
import { ISODateWithoutTime, parseDate } from "utils/dateUtils";
import { getIsDeferPayment } from "utils/getIsDeferPayment";

export type ForwardPricingFormData = {
  percentageToSell: number;
  cashPrice?: number;
  futuresPrice?: number;
  futuresSymbol?: string;
  basis?: number;
  deferPayment: SelectOption;
  defermentDate?: Date;
  dateOfContractCreation: Date;
  multiplier?: number;
  isManuallyCreated: boolean;
  dailyPricingId?: string;
};

const transformForwardPricingData = (
  data: ForwardPricingFormData,
  deferPayment: boolean,
  isManuallyCreated: boolean,
  dailyPricingId?: string
): CreateForwardContractRequest => ({
  ...data,
  deferPayment,
  defermentDate:
    deferPayment && data.defermentDate
      ? ISODateWithoutTime(data.defermentDate)
      : undefined,
  dateOfContractCreation: ISODateWithoutTime(data.dateOfContractCreation),
  dailyPricingId,
  isManuallyCreated,
});

export const CALCULATED_AT_PRE_HARVEST_MESSAGE = `Final Distance Adjustment and Net Price to be calculated at Post-Harvest.`;

const NewForwardPricingContractForm = ({
  cropYearDetail,
  currentForwardPricingData,
  cropYearId,
  farmId,
  isCurrentForwardPricingStale,
  refetchCurrentForwardPricing,
}: {
  cropYearDetail: CropYearDetailResponse | undefined;
  currentForwardPricingData: ForwardContractCurrentPricingResponse | undefined;
  cropYearId: string | undefined;
  farmId: string | undefined;
  isCurrentForwardPricingStale: boolean;
  refetchCurrentForwardPricing: () => Promise<
    QueryObserverResult<ForwardContractCurrentPricingResponse, BaseApiError>
  >;
}) => {
  const navigate = useNavigateWithQuery();
  const navigateToFarmView = useCallback(
    () =>
      navigate(
        `/${dashboardPaths.basePath}/${dashboardPaths.children.pricing}`
      ),
    [navigate]
  );
  const { onSuccessToast, onErrorToast } = useCustomToast();
  useScrollIntoView(isCurrentForwardPricingStale, "#futures-timeout-error");

  const {
    mutateAsync: onCreateForwardContract,
    isLoading: isCreateForwardContractLoading,
  } = useCreateForwardContract(cropYearId, farmId, false);
  const defaultDefermentDate = cropYearDetail?.defermentDateStart
    ? parseDate(cropYearDetail.defermentDateStart)
    : new Date();
  const methods = useForm<ForwardPricingFormData>({
    resolver: yupResolver(
      baseForwardContractSchema(
        defaultDefermentDate,
        currentForwardPricingData?.percentageAvailableToPrice ?? 0
      )
    ),
    defaultValues: {
      percentageToSell: 0,
      cashPrice: currentForwardPricingData?.cashPrice,
      futuresPrice: currentForwardPricingData?.futuresPrice,
      futuresSymbol: currentForwardPricingData?.futuresSymbol,
      basis: currentForwardPricingData?.basis,
      dateOfContractCreation: new Date(),
      multiplier: currentForwardPricingData?.multiplier,
      deferPayment: DEFER_PAYMENT_OPTION_YES,
      defermentDate: defaultDefermentDate,
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting },
  } = methods;

  const requiredFieldsNotFilled = useFormValidation(watch());

  const {
    alert: confirmAlert,
    onOpen: onConfirm,
    onClose,
    isOpen: confirmAlertIsOpen,
  } = useConfirmationAlert({
    title: "Are you sure you want to create this contract?",
    msg: "",
    confirmButtonText: `Yes`,
    secondaryButtonText: "Back",
    onConfirmAsync: () => {
      const formData = watch();
      const data = transformForwardPricingData(
        formData,
        getIsDeferPayment(formData.deferPayment.value),
        false,
        currentForwardPricingData?.dailyPricingId ?? ""
      );

      return onCreateForwardContract(data)
        .then(() => {
          navigate(
            `${dashboardPaths.basePath}/${dashboardPaths.children.pricing}`
          );
          onSuccessToast({
            message: "Contract successfully created",
          });
        })
        .catch(() => {
          onErrorToast({
            message: `Failed to create forward pricing contract`,
          });
          onClose();
        });
    },
    dialogOffset: DIALOG_OFFSET,
  });

  const isDeferPayment = getIsDeferPayment(watch("deferPayment").value);

  return (
    <VStack align="left" w="100%">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onConfirm)}>
          {confirmAlert}
          <VStack align="left" spacing={4}>
            <VStack align="left" spacing={4}>
              <PercentageToSellInput />
              <DeferPaymentInputs
                isDeferPayment={isDeferPayment}
                minDate={defaultDefermentDate}
              />
              {!isDeferPayment &&
                !!cropYearDetail?.firstPaymentDateWithoutDeferral && (
                  <PaymentDate
                    firstPaymentDateWithoutDeferral={parseDate(
                      cropYearDetail.firstPaymentDateWithoutDeferral
                    )}
                  />
                )}
            </VStack>
            <ForwardPricingContractStats
              cashPrice={currentForwardPricingData?.cashPrice ?? 0}
              futuresFriendlySymbol={
                currentForwardPricingData?.futuresFriendlySymbol ?? ""
              }
              futuresPrice={currentForwardPricingData?.futuresPrice ?? 0}
              basis={currentForwardPricingData?.basis ?? 0}
              isManuallyCreated={false}
              multiplier={currentForwardPricingData?.multiplier ?? 0}
              basePrice={currentForwardPricingData?.basePrice ?? 0}
            />
            <Text>{CALCULATED_AT_PRE_HARVEST_MESSAGE}</Text>
            <VStack w="100%">
              {isCurrentForwardPricingStale ? (
                <FuturesPriceTimeoutError
                  refetchCurrentPricing={refetchCurrentForwardPricing}
                />
              ) : (
                <SubmitButton
                  submitDisabled={
                    requiredFieldsNotFilled || !isDirty || confirmAlertIsOpen
                  }
                  isSubmitting={isSubmitting || isCreateForwardContractLoading}
                  buttonText="Submit"
                  w="100%"
                />
              )}
              <Button
                variant="outline"
                size="md"
                onClick={navigateToFarmView}
                w="100%"
              >
                Cancel
              </Button>
            </VStack>
          </VStack>
        </form>
      </FormProvider>
    </VStack>
  );
};

export default NewForwardPricingContractForm;
