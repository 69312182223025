import { useInfiniteQuery } from "react-query";

import { ManureYearFarmDto } from "api";
import { useManureYearService } from "contexts/ServiceProvider";
import { FarmsSortColumns } from "components/FarmListTable/listTableDataMapper";
import { BaseApiError, PaginationMeta, QueryParams } from "services/apiHelpers";

export type GetManureYearFarmsResponse = {
  manureYearFarms: ManureYearFarmDto[];
  meta: PaginationMeta;
};

export const MANURE_YEAR_FARMS_QUERY_KEY = "list-manure-year-farms";

const useGetManureYearFarms = (
  manureYearId?: string,
  queryParams?: Omit<QueryParams<FarmsSortColumns>, "page">
) => {
  const manureYearService = useManureYearService();

  return useInfiniteQuery<GetManureYearFarmsResponse, BaseApiError>(
    [MANURE_YEAR_FARMS_QUERY_KEY, manureYearId, queryParams],
    ({ pageParam = 0 }) =>
      manureYearId
        ? manureYearService
            .getManureYearFarms(manureYearId, {
              ...queryParams,
              page: pageParam,
              size: 10,
            })
            .then(({ data, meta }) => ({
              manureYearFarms: data,
              meta,
            }))
        : Promise.reject(new Error("manureYearId was not provided.")),
    { enabled: !!manureYearId }
  );
};

export default useGetManureYearFarms;
