import { useInfiniteQuery } from "react-query";

import { CropYearFarmDto } from "api";
import { useCropYearService } from "contexts/ServiceProvider";
import { BaseApiError, PaginationMeta, QueryParams } from "services/apiHelpers";
import { CropYearFarmsSortColumns } from "services/CropYearService";

export type GetCropYearFarmsResponse = {
  cropYearFarms: CropYearFarmDto[];
  meta: PaginationMeta;
};

export const USE_CROP_YEAR_FARMS_QUERY_KEY = "list-crop-year-farms";

const useCropYearFarms = (
  cropYearId?: string,
  queryParams?: Omit<QueryParams<CropYearFarmsSortColumns>, "page">
) => {
  const cropYearService = useCropYearService();

  return useInfiniteQuery<GetCropYearFarmsResponse, BaseApiError>(
    [USE_CROP_YEAR_FARMS_QUERY_KEY, cropYearId, queryParams],
    ({ pageParam = 0 }) =>
      cropYearId
        ? cropYearService
            .getCropYearFarms(cropYearId, { ...queryParams, page: pageParam })
            .then(({ data, meta }) => ({
              cropYearFarms: data,
              meta,
            }))
        : Promise.reject("Crop year ID was not provided."),
    { enabled: !!cropYearId }
  );
};

export default useCropYearFarms;
