import { useInfiniteQuery } from "react-query";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { BaseApiError, QueryParams } from "services/apiHelpers";
import {
  CropYearPricingOptionsSortColumns,
  GetCropYearPricingOptions,
} from "services/PricingOptionService";

export const GET_CROP_YEAR_PRICING_OPTIONS = "get-crop-year-pricing-options";

const useCropYearPricingOptions = ({
  cropYearId,
  queryParams,
}: {
  cropYearId?: string;
  queryParams?: QueryParams<CropYearPricingOptionsSortColumns>;
}) => {
  const pricingOptionService = usePricingOptionService();

  return useInfiniteQuery<GetCropYearPricingOptions, BaseApiError>(
    [GET_CROP_YEAR_PRICING_OPTIONS, { cropYearId }, queryParams],
    ({ pageParam = 0 }) =>
      cropYearId
        ? pricingOptionService.getCropYearPricingOptions({
            cropYearId,
            queryParams: {
              ...queryParams,
              page: pageParam,
            },
          })
        : Promise.reject("Must supply cropYearId."),
    { enabled: !!cropYearId }
  );
};

export default useCropYearPricingOptions;
