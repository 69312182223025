import { Navigate, useParams } from "react-router-dom";

import FormSkeletonLoadingState from "components/FormSkeletonLoadingState";
import RedirectToDashboard from "components/redirects/RedirectToDashboard";
import { EditDelayedPricingForm } from "forms/delayed-pricing";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useFarmDetail from "hooks/farm/farm-detail/useFarmDetail";
import useGetPricingOptionDetails from "hooks/pricing-option/useGetPricingOptionDetails";
import useSelectedCropYearId from "hooks/useSelectedCropYearId";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import dashboardPaths from "routes/dashboard/dashboardPaths";

const EditDelayedPricingPage = () => {
  const { id: pricingOptionId } = useParams();
  const { isEmployee } = useUserRoleFlags();
  const [selectedCropYearId] = useSelectedCropYearId();
  const [selectedFarmId] = useSelectedFarmId();

  const { data: pricingOption, isLoading: isLoadingPricingOptionDetails } =
    useGetPricingOptionDetails(pricingOptionId, selectedFarmId);
  const { data: cropYearDetailData, isLoading: isLoadingCropYearDetails } =
    useCropYearDetail(selectedCropYearId);
  const { data: farmDetailData, isLoading: isLoadingFarmDetails } =
    useFarmDetail(selectedFarmId);

  if (
    isLoadingPricingOptionDetails ||
    isLoadingCropYearDetails ||
    isLoadingFarmDetails
  ) {
    return <FormSkeletonLoadingState rowCount={10} />;
  }

  if (!pricingOption || !cropYearDetailData || !farmDetailData) {
    // TODO: Follow up on best error handling, if the contract, crop year, or farm fails to load
    return isEmployee ? (
      <Navigate
        to={`${dashboardPaths.basePath}/${dashboardPaths.children.pricing}`}
      />
    ) : (
      <RedirectToDashboard />
    );
  }

  return (
    <EditDelayedPricingForm
      pricingOption={pricingOption}
      cropYear={cropYearDetailData}
      farm={farmDetailData}
    />
  );
};

export default EditDelayedPricingPage;
