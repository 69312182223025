import { useCallback } from "react";

import { useQueryClient } from "react-query";

import { GET_CROP_YEAR_SUMMARY_QUERY_KEY } from "hooks/crop-year/useCropYearSummary";
import { GET_FARM_CROP_YEAR_SUMMARY_QUERY_KEY } from "hooks/crop-year/useFarmCropYearSummary";
import { USE_CROP_YEAR_FARMS_QUERY_KEY } from "hooks/crop-year/useCropYearFarms";
import { GET_CROP_YEAR_DETAIL_QUERY_KEY } from "hooks/crop-year/useCropYearDetails";
import { GET_CROP_YEARS_QUERY_KEY } from "hooks/crop-year/useCropYears";
import { GET_FIELD_DETAIL_QUERY_KEY } from "./useGetFieldDetail";
import { GET_FIELDS_QUERY_KEY } from "./useFieldsList";
import { GET_ALL_FIELDS_QUERY_KEY } from "./useAllFieldsList";
import { GET_FARM_CROP_YEAR_PRICING_OPTIONS } from "hooks/pricing-option/useFarmCropYearPricingOptions";
import { GET_PRICING_OPTION_DETAILS_QUERY_KEY } from "hooks/pricing-option/useGetPricingOptionDetails";

// TODO: Generalize this to maybe take in an enum of key (farm, field, etc) that maps tp a set of queries that should be refetched
const useRefetchFieldQueries = () => {
  const queryClient = useQueryClient();
  const refetch = useCallback(
    () =>
      Promise.all([
        queryClient.refetchQueries([GET_FIELDS_QUERY_KEY]),
        queryClient.refetchQueries([GET_ALL_FIELDS_QUERY_KEY]),
        queryClient.refetchQueries([GET_FIELD_DETAIL_QUERY_KEY]),
        queryClient.refetchQueries([GET_CROP_YEAR_SUMMARY_QUERY_KEY]),
        queryClient.refetchQueries([GET_FARM_CROP_YEAR_SUMMARY_QUERY_KEY]),
        queryClient.refetchQueries([USE_CROP_YEAR_FARMS_QUERY_KEY]),
        queryClient.refetchQueries([GET_CROP_YEAR_DETAIL_QUERY_KEY]),
        queryClient.refetchQueries([GET_CROP_YEARS_QUERY_KEY]),
        queryClient.invalidateQueries({
          queryKey: [GET_FARM_CROP_YEAR_PRICING_OPTIONS],
        }),
        queryClient.invalidateQueries({
          queryKey: [GET_PRICING_OPTION_DETAILS_QUERY_KEY],
        }),
      ]),
    [queryClient]
  );

  return { refetch };
};

export default useRefetchFieldQueries;
