import { useMutation } from "react-query";

import { CreateUserRequest } from "api";
import { useUserService } from "contexts/ServiceProvider";
import UserService from "services/UserService";
import { MutationHook, MutationHookResult } from "../hookTypeHelpers";

type CreateUserMutationResult = MutationHookResult<
  Awaited<ReturnType<UserService["createUser"]>>,
  CreateUserRequest
>;

const useCreateUserMutation = (): CreateUserMutationResult => {
  const userService = useUserService();

  return useMutation({
    mutationKey: ["create-user"],
    mutationFn: (userInfo) => {
      const allRequired = Object.entries(userInfo).every(
        ([, value]) => !!value
      );
      if (!allRequired) {
        throw new Error("Missing user info");
      }

      return userService.createUser(userInfo);
    },
  });
};

const useCreateUser = (): MutationHook<
  CreateUserMutationResult,
  "onSubmit"
> => {
  const {
    mutate: onSubmit,
    mutateAsync: onSubmitAsync,
    ...rest
  } = useCreateUserMutation();

  return { onSubmit, onSubmitAsync, ...rest };
};

export default useCreateUser;
