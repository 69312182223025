import { ManureYearResponse } from "api/models/manure-year-response";
import { ManureYearApiListManureYearsRequest } from "api";
import { useManureYearService } from "contexts/ServiceProvider";
import { useQuery } from "react-query";
import { BaseApiError, PaginationMeta } from "services/apiHelpers";
import { GetManureYearResponse } from "services/ManureYearService";

export const GET_MANURE_YEARS_QUERY_KEY = "manure-years";

export type ManureYearListItem = {
  id: string;
  year: number;
};

export type GetManureYearsListItemResponse = {
  manureYears: ManureYearListItem[];
  meta: PaginationMeta;
};

export const transformManureYearResponse = (
  manureYear: ManureYearResponse
): ManureYearListItem => ({
  id: manureYear.id,
  year: manureYear.year,
});

const useGetManureYears = (
  enabled = true,
  queryParams?: ManureYearApiListManureYearsRequest
) => {
  const manureYearService = useManureYearService();

  return useQuery<
    GetManureYearResponse,
    BaseApiError,
    GetManureYearsListItemResponse
  >({
    queryKey: [
      GET_MANURE_YEARS_QUERY_KEY,
      queryParams,
      { page: queryParams?.page },
    ],
    queryFn: () =>
      manureYearService.getManureYears({
        ...queryParams,
        page: queryParams?.page ?? 0,
      }),
    select: ({ data, meta }) => ({
      manureYears: data.map(transformManureYearResponse),
      meta,
    }),
    enabled,
  });
};

export default useGetManureYears;
