import { useMemo } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { Stack } from "@chakra-ui/react";
import { CSVUploadJobResponseStatusEnum } from "api";
import {
  CsvResultsErrorMessage,
  CsvResultsSuccessMessage,
  CsvStep,
  ErrorTable,
  ErrorColumnProps,
} from "components/CsvUpload";
import WithSpinner from "components/WithSpinner";
import useCsvUploadJob from "hooks/csv-upload/useCsvUploadJob";
import ErrorGuide, { GuideListItemType } from "pages/csv-uploads/error-guide";

const ResultsWithErrors = ({
  errorTableColumns,
  additionalErrorMessage,
  errorGuideList,
}: {
  errorTableColumns: ErrorColumnProps[];
  additionalErrorMessage: string;
  errorGuideList: Array<GuideListItemType>;
}) => {
  const [, setFile] =
    useOutletContext<[File, (file: File | undefined) => void]>();
  const { jobId } = useParams();
  const { data, isLoading } = useCsvUploadJob(jobId, (data) => {
    if (data?.status === CSVUploadJobResponseStatusEnum.Success) {
      setFile(undefined);
    }
  });
  const tableData = useMemo(
    () =>
      Object.entries(data?.invalidRowData ?? []).map(
        ([csvRowIndex, rowData]) => {
          const rowErrors = data?.rowErrorsByHeader[csvRowIndex] ?? {};

          return Object.fromEntries(
            errorTableColumns.map(({ id: columnId, csvHeading }) => [
              columnId,
              { value: rowData[csvHeading], isError: !!rowErrors[csvHeading] },
            ])
          );
        }
      ),
    [data, errorTableColumns]
  );

  return (
    <>
      <CsvStep
        currentStep={3}
        totalSteps={3}
        description="Upload Report"
        showRetryButton={true}
      />
      <WithSpinner isLoading={isLoading}>
        <Stack spacing={8}>
          <Stack spacing={4}>
            {!!data?.itemsProcessedSuccessfully && (
              <CsvResultsSuccessMessage>
                {`${data.itemsProcessedSuccessfully} out of ${data.totalItems} Fields successfully uploaded`}
              </CsvResultsSuccessMessage>
            )}
            <CsvResultsErrorMessage>
              {`${data?.itemsWithErrors} out of ${data?.totalItems} Fields have errors and have not been uploaded. ${additionalErrorMessage}`}
            </CsvResultsErrorMessage>
            <ErrorTable columns={errorTableColumns} data={tableData} />
          </Stack>
          <ErrorGuide errorGuideListData={errorGuideList} />
        </Stack>
      </WithSpinner>
    </>
  );
};

export default ResultsWithErrors;
