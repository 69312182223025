import { useInfiniteQuery } from "react-query";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { BaseApiError, QueryParams } from "services/apiHelpers";
import {
  FarmCropYearPricingOptionsSortColumns,
  GetFarmCropYearPricingOptions,
} from "services/PricingOptionService";

export const GET_FARM_CROP_YEAR_PRICING_OPTIONS =
  "get-farm-crop-year-pricing-options";

const useFarmCropYearPricingOptions = ({
  cropYearId,
  farmId,
  queryParams,
}: {
  cropYearId?: string;
  farmId?: string;
  queryParams?: QueryParams<FarmCropYearPricingOptionsSortColumns>;
}) => {
  const pricingOptionService = usePricingOptionService();

  return useInfiniteQuery<GetFarmCropYearPricingOptions, BaseApiError>(
    [GET_FARM_CROP_YEAR_PRICING_OPTIONS, { cropYearId, farmId }, queryParams],
    ({ pageParam = 0 }) =>
      cropYearId && farmId
        ? pricingOptionService.getFarmCropYearPricingOptions({
            cropYearId,
            farmId,
            queryParams: {
              ...queryParams,
              page: pageParam,
            },
          })
        : Promise.reject("Must supply cropYearId and farmId."),
    { enabled: !!cropYearId && !!farmId, cacheTime: 0 }
  );
};

export default useFarmCropYearPricingOptions;
