import { useQuery } from "react-query";

import { MeResponse } from "api";
import AuthService from "services/AuthService";
import { BaseApiError } from "services/apiHelpers";
import { useAuthService } from "contexts/ServiceProvider";

type GetUserInfoResponse = Awaited<ReturnType<AuthService["userInfo"]>>;

export const GET_USER_INFO_QUERY_KEY = "get-user-info";

// Call this at the top of the tree as the user info is used throughout the app
export const useGetUserInfo = (
  enabled = true,
  initialData?: MeResponse,
  onSuccess?: (data: MeResponse) => void
) => {
  const authService = useAuthService();

  return useQuery<GetUserInfoResponse, BaseApiError>({
    queryKey: [GET_USER_INFO_QUERY_KEY],
    queryFn: () => authService.userInfo(),
    enabled,
    initialData,
    onSuccess,
  });
};
