import { useMutation } from "react-query";

import { UpdateCropYearRequest } from "api";
import { useCropYearService } from "contexts/ServiceProvider";
import CropYearService from "services/CropYearService";
import useRefetchFieldQueries from "hooks/field/useRefetchFieldQueries";
import { MutationHookResult } from "../hookTypeHelpers";

export const UPDATE_CROP_YEAR_QUERY_KEY = "update-crop-year";

type UpdateCropYearMutationResult = MutationHookResult<
  Awaited<ReturnType<CropYearService["updateCropYear"]>>,
  UpdateCropYearRequest
>;

const useUpdateCropYear = (
  cropYearId?: string
): UpdateCropYearMutationResult => {
  const cropYearService = useCropYearService();
  const { refetch } = useRefetchFieldQueries();

  return useMutation({
    mutationKey: [UPDATE_CROP_YEAR_QUERY_KEY, { id: cropYearId }],
    mutationFn: (cropYearInfo) =>
      cropYearId
        ? cropYearService.updateCropYear(cropYearId, cropYearInfo)
        : Promise.reject("Must provide crop year id"),
    onSuccess: () => refetch(),
  });
};

export default useUpdateCropYear;
