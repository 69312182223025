import { useMutation } from "react-query";

import { useProfilePictureService } from "contexts/ServiceProvider";
import ProfilePictureService from "services/ProfilePictureService";
import { MutationHook, MutationHookResult } from "hooks/hookTypeHelpers";

type UploadProfilePictureResult = MutationHookResult<
  Awaited<ReturnType<ProfilePictureService["setRegistrationProfilePicture"]>>,
  { file: File; xOffset?: number; yOffset?: number }
>;

const useUploadRegistrationProfilePictureMutation = (
  token?: string
): UploadProfilePictureResult => {
  const profilePictureService = useProfilePictureService();

  return useMutation({
    mutationKey: ["profile-picture", token],
    mutationFn: ({ file, xOffset, yOffset }) => {
      return token
        ? profilePictureService.setRegistrationProfilePicture(
            token,
            file,
            xOffset,
            yOffset
          )
        : Promise.reject("Invitation token is required");
    },
  });
};

const useUploadRegistrationProfilePicture = (
  token?: string
): MutationHook<UploadProfilePictureResult, "onUpload"> => {
  const {
    mutate: onUpload,
    mutateAsync: onUploadAsync,
    ...rest
  } = useUploadRegistrationProfilePictureMutation(token);

  return { onUpload, onUploadAsync, ...rest };
};

export default useUploadRegistrationProfilePicture;
