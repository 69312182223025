import { useInfiniteQuery } from "react-query";
import { useHaylageYearService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";
import { GetHaylageYearFarmDocumentsResponse } from "services/HaylageYearService";

export const HAYLAGE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY =
  "haylage-year-farm-document-link";

const useGetHaylageYearFarmDocuments = (
  haylageYearId: string | undefined,
  farmId: string | undefined
) => {
  const haylageService = useHaylageYearService();

  return useInfiniteQuery<GetHaylageYearFarmDocumentsResponse, BaseApiError>(
    [HAYLAGE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY, { haylageYearId, farmId }],
    ({ pageParam = 0 }) =>
      farmId && haylageYearId
        ? haylageService.getHaylageYearFarmDocuments(haylageYearId, farmId, {
            page: pageParam,
            size: 10,
          })
        : Promise.reject(new Error("Missing haylageYearId or farmId")),
    { enabled: !!haylageYearId || !!farmId }
  );
};

export default useGetHaylageYearFarmDocuments;
