import { useQuery } from "react-query";

import { CropYearDetailResponse } from "api";
import { useCropYearService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";

export const GET_CROP_YEAR_DETAIL_QUERY_KEY = "crop-year-detail";

const useCropYearDetail = (cropYearId?: string) => {
  const cropYearService = useCropYearService();

  return useQuery<CropYearDetailResponse | undefined, BaseApiError>({
    queryKey: [GET_CROP_YEAR_DETAIL_QUERY_KEY, cropYearId],
    queryFn: () =>
      cropYearId
        ? cropYearService.getCropYearDetail({ id: cropYearId })
        : Promise.reject("cropYearId is undefined"),
    enabled: !!cropYearId,
  });
};

export default useCropYearDetail;
