import { useMutation } from "react-query";
import { useFieldService } from "contexts/ServiceProvider";

const useUploadFieldCsv = () => {
  const fieldService = useFieldService();

  return useMutation({
    mutationKey: ["upload-field-csv"],
    mutationFn: ({ csvFile }: { csvFile: File }) =>
      fieldService.uploadFieldsCsv(csvFile),
  });
};

export default useUploadFieldCsv;
