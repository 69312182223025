import { ReactElement, useCallback } from "react";

import { Divider, Stack } from "@chakra-ui/react";
import { SubmitHandler, useFormContext } from "react-hook-form";

import {
  FarmUserClaimDto,
  FarmUserClaimRequestDtoRoleEnum,
  UpdateUserRequestRoleEnum,
} from "api";
import { extractNumbers } from "utils/stringUtils";
import usersPaths from "routes/user/list/usersPaths";
import useFormValidation from "hooks/useFormValidation";
import useUpdateUserDetails from "hooks/user/useUpdateUserDetails";
import useResendInvite from "hooks/user/useResendInvite";
import useCustomToast from "hooks/useCustomToast";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useNavigateWithQuery from "hooks/useNavigateWithQuery";
import { PageHeader } from "components/page-components";
import SubmitButton from "components/SubmitButton";
import TextHeading from "components/TextHeading";
import FormButtonGroup from "components/FormButtonGroup";
import { UserRoleSelect } from "components/inputs";
import UserDetailFormInputs from "forms/user-detail";
import FarmClaimInputs from "forms/user-detail/FarmClaimInputs";
import {
  RESEND_SINGLE_INVITE_FAILED_MESSAGE,
  RESEND_SINGLE_INVITEE_SUCCESS_MESSAGE,
} from "constants/messages";
import { UserDetailFields } from "./UserDetailsFormContext";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import { EMPTY_PHONE } from "components/inputs/PhoneInput";
import { FormStack } from "layouts/FormLayout";

export type UserDetailsFormProps = {
  userId: string;
  isPending: boolean;
  isEmployee: boolean;
  farmClaims: FarmUserClaimDto[];
};
const UserDetailsForm = ({
  userId,
  isEmployee,
  isPending,
  farmClaims,
}: UserDetailsFormProps): ReactElement => {
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting, isDirty },
  } = useFormContext<UserDetailFields>();
  const { isEmployeeOnly } = useUserRoleFlags();
  const { onUpdateAsync, isLoading: updateUserIsLoading } =
    useUpdateUserDetails(userId);
  const navigate = useNavigateWithQuery();
  const { onSuccessToast, onErrorToast } = useCustomToast();
  const [farmId] = useSelectedFarmId();

  const onSave: SubmitHandler<UserDetailFields> = useCallback(
    (data) => {
      return onUpdateAsync({
        ...data,
        phoneNumber: extractNumbers(data.phoneNumber),
        farmUserClaims: farmClaims.map((farmClaim) => ({
          farmId: farmClaim.farmId,
          userId: userId,
          id: farmClaim.id,
          role: farmClaim.role
            .value as unknown as FarmUserClaimRequestDtoRoleEnum,
        })),
        role: data.role.value as UpdateUserRequestRoleEnum,
      })
        .then(() => {
          onSuccessToast({ message: "Changes were saved successfully." });
          navigate(`/${usersPaths.basePath}`);
        })
        .catch(() => {
          onErrorToast({ message: "Changes were not saved." });
        });
    },
    [farmClaims, userId, navigate, onErrorToast, onSuccessToast, onUpdateAsync]
  );

  const { onResendInvitesAsync, isLoading: resendInviteIsLoading } =
    useResendInvite();

  const onSubmit: SubmitHandler<UserDetailFields> = useCallback(
    (data) => {
      if (isPending) {
        return onResendInvitesAsync({
          userIds: [userId],
          farmId,
        })
          .then(() =>
            onSuccessToast({
              message: RESEND_SINGLE_INVITEE_SUCCESS_MESSAGE,
            })
          )
          .catch(() =>
            onErrorToast({
              message: RESEND_SINGLE_INVITE_FAILED_MESSAGE,
            })
          );
      }

      return onSave(data);
    },
    [
      isPending,
      onErrorToast,
      onResendInvitesAsync,
      onSave,
      onSuccessToast,
      farmId,
      userId,
    ]
  );

  const watched = watch();
  const requiredFieldsNotFilled =
    useFormValidation(watched) || watched.phoneNumber === EMPTY_PHONE;

  const permissionsHeading = isEmployee ? "Riverview" : "Farm";

  const submitDisabled =
    (isEmployeeOnly &&
      watch("role").value !== UpdateUserRequestRoleEnum.FarmAdmin) ||
    (!isPending && (requiredFieldsNotFilled || !isDirty));

  return (
    <FormStack onSubmit={handleSubmit(onSubmit)}>
      <PageHeader heading="User Information">
        <FormButtonGroup>
          <SubmitButton
            submitDisabled={submitDisabled}
            isSubmitting={
              isSubmitting || updateUserIsLoading || resendInviteIsLoading
            }
            buttonText={isPending ? "Resend Invite" : "Save"}
          />
        </FormButtonGroup>
      </PageHeader>
      <Stack spacing={6}>
        <UserDetailFormInputs isDisabled={isEmployeeOnly || isPending} />
        <Divider />
        <Stack spacing={4}>
          <TextHeading headingType="h5">{`${permissionsHeading} Permissions`}</TextHeading>
          {isEmployee ? (
            <UserRoleSelect<UserDetailFields>
              id="role"
              userId={userId}
              isDisabled={isEmployeeOnly || isPending}
            />
          ) : (
            <FarmClaimInputs farmClaims={farmClaims} />
          )}
        </Stack>
      </Stack>
    </FormStack>
  );
};

export default UserDetailsForm;
