import { useMutation, useQueryClient } from "react-query";

import { useHaylageYearService } from "contexts/ServiceProvider";
import { GET_HAYLAGE_YEAR_ADDABLE_FARMS_QUERY_KEY } from "hooks/api/haylage-year/useGetAddableFarmsForHaylageYear";
import { HAYLAGE_YEAR_FARMS_QUERY_KEY } from "hooks/api/haylage-year/useGetHaylageYearFarms";

const ASSIGN_FARM_TO_HAYLAGE_YEAR_MUTATION_KEY = "assign-farm-to-haylage-year";

const useAssignFarmToHaylageYear = (haylageYearId?: string) => {
  const haylageYearService = useHaylageYearService();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [ASSIGN_FARM_TO_HAYLAGE_YEAR_MUTATION_KEY, { haylageYearId }],
    mutationFn: (farmIds: Array<string>) =>
      haylageYearId && farmIds.length > 0
        ? haylageYearService.assignFarmsToHaylageYear({
            id: haylageYearId,
            requestBody: farmIds,
          })
        : Promise.reject(new Error("Must have haylageYearId and farmIds")),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries([HAYLAGE_YEAR_FARMS_QUERY_KEY]),
        queryClient.invalidateQueries([
          GET_HAYLAGE_YEAR_ADDABLE_FARMS_QUERY_KEY,
        ]),
      ]),
  });
};

export default useAssignFarmToHaylageYear;
