import { useQuery } from "react-query";

import { useAuthService } from "contexts/ServiceProvider";
import AuthService from "services/AuthService";
import { customAxiosInstance } from "services/customApiConfig";
import { BaseApiError } from "services/apiHelpers";
import { getRefreshToken, storeRefreshToken } from "utils/authLocalStorage";
import { clearAuthData } from "utils/authUtils";

export const AUTH_TOKENS_QUERY_KEY = "auth-tokens";

type GetRefreshTokenResult = Awaited<ReturnType<AuthService["refreshToken"]>>;

export const setAuthHeader = (token?: string) => {
  if (token) {
    customAxiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  } else {
    delete customAxiosInstance.defaults.headers.common.Authorization;
  }
};

const useAuthTokens = () => {
  const authService = useAuthService();
  const refreshToken = getRefreshToken();

  const onAuthCleanup = () => {
    clearAuthData(true);
    window.location.reload();
  };

  return useQuery<GetRefreshTokenResult, BaseApiError>({
    // We don't want to put the token in the query key for security reasons
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [AUTH_TOKENS_QUERY_KEY],
    queryFn: () =>
      refreshToken
        ? authService.refreshToken(refreshToken)
        : Promise.reject("Unable to authenticate"),
    enabled: !!refreshToken,
    retry: 0,
    refetchOnReconnect: true,
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: ({ refreshToken: _refreshToken, accessToken }) => {
      if (!_refreshToken) {
        return onAuthCleanup();
      } else {
        // In v4 this will no longer work as expected when doing a login mutation via useLogin
        // https://tanstack.com/query/v4/docs/guides/migrating-to-react-query-4#onsuccess-is-no-longer-called-from-setquerydata
        storeRefreshToken(_refreshToken);
        setAuthHeader(accessToken);
      }
    },
    onError: (error?: BaseApiError) => {
      const errorStatus = error?.response?.status ?? 0;
      if (errorStatus >= 400 && errorStatus < 500) {
        // An authentication error, so cleanup
        return onAuthCleanup();
      }
    },
  });
};

export default useAuthTokens;
