import { useQuery } from "react-query";
import { useNotificationService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";
import { NewCropYearNotification } from "services/NotificationService";

export const GET_NOTIFICATIONS_LIST_QUERY_KEY = "get-notification-list";

const useGetNotificationList = () => {
  const notificationsService = useNotificationService();

  return useQuery<NewCropYearNotification[], BaseApiError>({
    queryKey: [GET_NOTIFICATIONS_LIST_QUERY_KEY],
    queryFn: () => {
      return notificationsService.getNotificationList();
    },
  });
};

export default useGetNotificationList;
