import { useMutation } from "react-query";

import { CreateDailyPricingRequest } from "api";
import { queryClient } from "contexts/ReactQueryProvider";
import { useDailyPricingService } from "contexts/ServiceProvider";
import { GET_NEW_CROP_FUTURES_QUERY_KEY } from "hooks/futures/useGetNewCropFutures";
import { MutationHookResult } from "hooks/hookTypeHelpers";
import DailyPricingService from "services/DailyPricingService";
import { GET_DAILY_PRICING_QUERY_KEY } from "./useGetDailyPricing";

type CreateDailyPricingMutationResult = MutationHookResult<
  Awaited<ReturnType<DailyPricingService["createDailyPricing"]>>,
  CreateDailyPricingRequest
>;

const CREATE_DAILY_PRICING_MUTATION_KEY = "create-daily-pricing";

const useCreateDailyPricing = (): CreateDailyPricingMutationResult => {
  const dailyPricingService = useDailyPricingService();

  return useMutation({
    mutationKey: [CREATE_DAILY_PRICING_MUTATION_KEY],
    mutationFn: (createDailyPricingRequest) => {
      return createDailyPricingRequest
        ? dailyPricingService.createDailyPricing({ createDailyPricingRequest })
        : Promise.reject(new Error("createDailyPricingRequest is required"));
    },
    onSuccess: async () => {
      await queryClient.refetchQueries([GET_DAILY_PRICING_QUERY_KEY]);
      await queryClient.invalidateQueries([GET_NEW_CROP_FUTURES_QUERY_KEY]);
    },
  });
};

export default useCreateDailyPricing;
