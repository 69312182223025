import { FarmListDto } from "api";
import { AddableFarmsSortColumns } from "components/AddFarmsToYear/addFarmsTableColumns";
import { useManureYearService } from "contexts/ServiceProvider";
import { useQuery } from "react-query";
import { BaseApiError, QueryParams } from "services/apiHelpers";

export const GET_MANURE_YEAR_ADDABLE_FARMS_QUERY_KEY =
  "addable-farms-manure-year";

const useGetAddableFarmsForManureYear = (
  manureYearId?: string,
  sort?: Omit<QueryParams<AddableFarmsSortColumns>, "page">
) => {
  const manureYearService = useManureYearService();

  return useQuery<FarmListDto[], BaseApiError>({
    queryKey: [GET_MANURE_YEAR_ADDABLE_FARMS_QUERY_KEY, manureYearId, sort],
    queryFn: () =>
      manureYearId
        ? manureYearService.getAddableFarmsForManureYear(manureYearId, sort)
        : Promise.reject(new Error("Must have manureYearId")),
  });
};

export default useGetAddableFarmsForManureYear;
