import { useCallback } from "react";
import CatastrophicErrorState from "./CatastrophicErrorState";
import useUploadActualTonsCsv from "hooks/csv-upload/useUploadActualTonsCsv";
import dashboardPaths from "routes/dashboard/dashboardPaths";

const ActualTonsCatastrophicErrorState = () => {
  const { mutateAsync, isLoading } = useUploadActualTonsCsv();

  const onRetryUploadCsvFile = useCallback(
    (file: File) => mutateAsync({ csvFile: file }),
    [mutateAsync]
  );

  return (
    <CatastrophicErrorState
      isLoading={isLoading}
      onRetry={onRetryUploadCsvFile}
      linkText="Back to Fields List"
      linkTo={`/${dashboardPaths.basePath}/${dashboardPaths.children.allFields}`}
    />
  );
};

export default ActualTonsCatastrophicErrorState;
