import { useQuery } from "react-query";
import { useCropYearService } from "contexts/ServiceProvider";

const useNextCropYear = (id?: string) => {
  const cropYearService = useCropYearService();

  return useQuery({
    queryKey: ["next-crop-year"],
    queryFn: () => cropYearService.getNextCropYear(),
    enabled: !id,
  });
};

export default useNextCropYear;
