import { useMutation } from "react-query";

import { useFarmService } from "contexts/ServiceProvider";
import { MutationHook, MutationHookResult } from "hooks/hookTypeHelpers";
import FarmService from "services/FarmService";

type RejectFarmMutationResult = MutationHookResult<
  Awaited<ReturnType<FarmService["rejectFarm"]>>,
  { farmId: string }
>;

const useRejectFarmMutation = (): RejectFarmMutationResult => {
  const farmService = useFarmService();

  return useMutation({
    mutationKey: ["reject-farm"],
    mutationFn: ({ farmId }) => {
      return farmService.rejectFarm(farmId);
    },
  });
};

const useRejectFarm = (): MutationHook<
  RejectFarmMutationResult,
  "onReject"
> => {
  const {
    mutate: onReject,
    mutateAsync: onRejectAsync,
    ...rest
  } = useRejectFarmMutation();

  return { onReject, onRejectAsync, ...rest };
};

export default useRejectFarm;
