import { ReactElement } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Button, VStack } from "@chakra-ui/react";
import SubmitButton from "components/SubmitButton";
import { SelectOption } from "components/Select";
import useCustomToast from "hooks/useCustomToast";
import { ISODateWithoutTime } from "utils/dateUtils";
import useUpdateAveragePricingContract from "hooks/pricing-option/useUpdateAveragePricingContract";
import PartialAveragePricingFormInputs from "./PartialAveragePricingFormInputs";
import { PricingOptionResponse } from "api";
import { getIsDeferPayment } from "utils/getIsDeferPayment";
import { partialAveragePricingSchema } from "../../schemas/partialAveragePricingSchema";

type PartialAveragePricingFormData = {
  deferPayment: SelectOption;
  paymentDate: Date;
};

type PartialAveragePricingFormProps = {
  ids: {
    pricingOptionId?: string;
    cropYearId?: string;
    farmId?: string;
  };
  defaultValues: PartialAveragePricingFormData;
  averagePrice: PricingOptionResponse;
  cropYearValues: {
    defermentDate: Date;
    firstPaymentDateWithoutReferral: Date;
  };
  onClose: () => void;
};

const PartialAveragePricingForm = ({
  ids,
  averagePrice,
  defaultValues,
  cropYearValues,
  onClose,
}: PartialAveragePricingFormProps): ReactElement => {
  const {
    mutateAsync: onUpdateAveragePricingAsync,
    isLoading: updateAveragePricingIsLoading,
  } = useUpdateAveragePricingContract(
    ids.pricingOptionId,
    ids.cropYearId,
    ids.farmId
  );

  const { onSuccessToast, onErrorToast } = useCustomToast();

  const methods = useForm<PartialAveragePricingFormData>({
    resolver: yupResolver(
      partialAveragePricingSchema({
        defaultDefermentDate: cropYearValues.defermentDate,
      })
    ),
    defaultValues,
  });

  const onSubmit = (data: PartialAveragePricingFormData) => {
    const deferPayment = getIsDeferPayment(data.deferPayment.value);
    const averagePricing = {
      percentageToSell: averagePrice.percentageToSell,
      multiplier: averagePrice.multiplier,
      deferPayment,
      defermentDate: deferPayment
        ? ISODateWithoutTime(data.paymentDate)
        : undefined,
    };

    return onUpdateAveragePricingAsync(averagePricing, {
      onSuccess: () => {
        onSuccessToast({
          message: "Contract successfully updated",
        });
        onClose();
      },
      onError: () => {
        onErrorToast({ message: "Failed to update contract" });
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PartialAveragePricingFormInputs
          defaultDefermentDate={cropYearValues.defermentDate}
          deferPayment={getIsDeferPayment(
            methods.getValues("deferPayment").value
          )}
          firstPaymentDateWithoutReferral={
            cropYearValues.firstPaymentDateWithoutReferral
          }
        />
        <VStack w={"100%"} mt={3}>
          <SubmitButton
            w="100%"
            submitDisabled={!methods.formState.isDirty}
            isSubmitting={
              methods.formState.isSubmitting || updateAveragePricingIsLoading
            }
            buttonText="Save"
          />
          <Button w={"100%"} variant={"outline"} onClick={onClose}>
            Cancel
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default PartialAveragePricingForm;
