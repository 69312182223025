import { useInfiniteQuery } from "react-query";
import { useManureYearService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";
import { GetManureYearFarmDocumentsResponse } from "services/ManureYearService";

export const MANURE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY =
  "manure-year-farm-document-link";

const useGetManureYearFarmDocuments = (
  manureYearId: string | undefined,
  farmId: string | undefined
) => {
  const manureService = useManureYearService();

  return useInfiniteQuery<GetManureYearFarmDocumentsResponse, BaseApiError>(
    [MANURE_YEAR_FARM_DOCUMENT_LINK_QUERY_KEY, { manureYearId, farmId }],
    ({ pageParam = 0 }) =>
      farmId && manureYearId
        ? manureService.getManureYearFarmDocuments(manureYearId, farmId, {
            page: pageParam,
            size: 10,
          })
        : Promise.reject(new Error("Missing manureYearId or farmId")),
    { enabled: !!manureYearId || !!farmId }
  );
};

export default useGetManureYearFarmDocuments;
