import { useQuery } from "react-query";
import { useFieldService } from "contexts/ServiceProvider";
import { MinimumFarmListDto } from "api";
import { BaseApiError } from "services/apiHelpers";

export const GET_FIELD_ADDABLE_FARMS_QUERY_KEY = "get-field-addable-farms";

const useGetFieldAddableFarms = (fieldId?: string, farmId?: string) => {
  const fieldService = useFieldService();

  return useQuery<MinimumFarmListDto[] | undefined, BaseApiError>({
    queryKey: [GET_FIELD_ADDABLE_FARMS_QUERY_KEY, { fieldId, farmId }],
    queryFn: () =>
      fieldId && farmId
        ? fieldService.getFieldAddableFarms({ fieldId, farmId })
        : Promise.reject("fieldId and farmId are required"),
    enabled: !!fieldId && !!farmId,
  });
};

export default useGetFieldAddableFarms;
