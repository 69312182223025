import { useQuery } from "react-query";

import { FuturesPriceDto } from "api";
import { useFuturesService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";

export const GET_AVAILABLE_NEW_CROP_FUTURES_QUERY_KEY =
  "get-available-new-crop-futures";

export const useGetAvailableNewCropFutures = () => {
  const futuresService = useFuturesService();

  return useQuery<FuturesPriceDto[], BaseApiError>({
    queryKey: [GET_AVAILABLE_NEW_CROP_FUTURES_QUERY_KEY],
    queryFn: () => futuresService.getAvailableNewCropFutures(),
  });
};

export default useGetAvailableNewCropFutures;
