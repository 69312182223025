import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";

import {
  CropYearDetailResponse,
  CropYearDetailResponseTimeOfYearEnum,
} from "api";
import useCropYears, { CropYearListItem } from "hooks/crop-year/useCropYears";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useSelectedCropYearId from "hooks/useSelectedCropYearId";

type CropYear = {
  cropYear?: CropYearListItem;
  cropYearDetail?: CropYearDetailResponse;
  setCropYear?: (cropYear: CropYearListItem) => void;
  isLoading?: boolean;
  isPreHarvest?: boolean;
};

const CropYearContext = createContext<CropYear>({});

export const CropYearContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [cropYearId, setCropYearId] = useSelectedCropYearId();
  const { data, isLoading: yearIsLoading } = useCropYears();
  const { data: cropYearDetail, isLoading: detailIsLoading } =
    useCropYearDetail(cropYearId);

  useEffect(() => {
    const cropYears = data?.cropYears;
    if (
      (!cropYearId ||
        (cropYearId &&
          !cropYears?.find((cropYear) => cropYear.id === cropYearId))) &&
      cropYears?.[0]
    ) {
      setCropYearId(cropYears[0].id);
    }
  }, [cropYearId, data?.cropYears, setCropYearId]);

  const isPreHarvest = useMemo(
    () =>
      cropYearDetail?.timeOfYear ===
      CropYearDetailResponseTimeOfYearEnum.PreHarvest,
    [cropYearDetail]
  );

  return (
    <CropYearContext.Provider
      value={{
        cropYear: !yearIsLoading
          ? data?.cropYears.find((cropYear) => cropYear.id === cropYearId) ??
            data?.cropYears[0]
          : undefined,
        setCropYear: (cropYear) => setCropYearId(cropYear.id),
        isLoading: yearIsLoading || detailIsLoading,
        cropYearDetail,
        isPreHarvest,
      }}
    >
      {children}
    </CropYearContext.Provider>
  );
};

export const useCropYearContext = (): CropYear => useContext(CropYearContext);
