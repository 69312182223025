import { QueryOptions, useQuery } from "react-query";
import { ListFeatureFlagResponse } from "api";
import { services } from "contexts/ServiceProvider";
import { queryClient } from "contexts/ReactQueryProvider";
import { BaseApiError } from "services/apiHelpers";

type Flags = Record<string, boolean>;

let envFlags: Flags = {};

// Load default flags from env
try {
  envFlags = Object.fromEntries(
    process.env.REACT_APP_FEATURE_FLAGS?.split(",")?.map((flag) => {
      const [flagName, value]: (string | undefined)[] = flag.split(":");

      return [
        flagName ?? "",
        value?.toLocaleLowerCase() === "true" || value === "1",
      ];
    }) ?? []
  );
} catch (e) {
  // eslint-disable-next-line no-console
  console.debug("Env not defined, feature flags unset.", (e as Error).message);
}

export const GET_FEATURE_FLAGS_QUERY_KEY = "get-feature-flags";

const featureFlagsQueryOptions: QueryOptions<
  ListFeatureFlagResponse,
  BaseApiError
> = {
  queryKey: [GET_FEATURE_FLAGS_QUERY_KEY],
  queryFn: () =>
    services.featureFlagService
      .getFeatureFlags()
      .then((data) => ({ ...data, flags: { ...envFlags, ...data.flags } })),
  initialData: { flags: envFlags }, // TODO: Remove initial data, if the env flags should be supplemented by the BE
};

// TODO: Change cache and stale time, if the env flags should be supplemented by the BE
export const getFeatureFlags = () =>
  queryClient.fetchQuery({
    ...featureFlagsQueryOptions,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

// TODO: Change cache and stale time, if the env flags should be supplemented by the BE
const useFeatureFlags = () => {
  return useQuery<ListFeatureFlagResponse, BaseApiError>({
    ...featureFlagsQueryOptions,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
};

export const useHasFeatureFlag = (flagName: string): boolean => {
  const { data } = useFeatureFlags();

  return !!data?.flags[flagName];
};

export default useFeatureFlags;
