import { useMutation } from "react-query";

import { UpdateContractDefermentRequest } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { MutationHookResult } from "hooks/hookTypeHelpers";
import PricingOptionService from "services/PricingOptionService";
import useDashboardRefetchQueries from "hooks/crop-year/useDashboardRefetchQueries";

type UpdateForwardContractDefermentMutationResult = MutationHookResult<
  Awaited<ReturnType<PricingOptionService["updateForwardContactDeferment"]>>,
  UpdateContractDefermentRequest
>;

export const UPDATE_FORWARD_CONTRACT_DEFERMENT_QUERY_KEY =
  "update-forward-contract-deferment";

const useUpdateForwardContractDeferment = (
  contractId?: string,
  cropYearId?: string,
  farmId?: string
): UpdateForwardContractDefermentMutationResult => {
  const pricingOptionService = usePricingOptionService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationFn: (forwardContractDeferment) => {
      if (contractId && cropYearId && farmId) {
        return forwardContractDeferment
          ? pricingOptionService.updateForwardContactDeferment({
              contractId,
              cropYearId,
              farmId,
              forwardContractDeferment,
            })
          : Promise.reject("Must provide valid forward contract information");
      } else {
        return Promise.reject("Missing contract id, crop year id, or farm id");
      }
    },
    mutationKey: [UPDATE_FORWARD_CONTRACT_DEFERMENT_QUERY_KEY],
    onSuccess: () => refetch(),
  });
};

export default useUpdateForwardContractDeferment;
