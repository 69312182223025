import { useFieldArray, useFormContext } from "react-hook-form";
import { Stack, StackDirection, useBreakpointValue } from "@chakra-ui/react";

import TextHeading from "components/TextHeading";
import { FormInputs, StandardInput } from "components/inputs";
import EstimatedYieldInput from "components/inputs/EstimatedYieldInput";
import FormSkeletonLoadingState from "components/FormSkeletonLoadingState";
import useRegionOptions from "hooks/region/useRegionOptions";
import { CropYearDetailFields } from "pages/crop-year-detail/CropYearFormContext";
import { mobileStyleBreakpoint } from "utils/styleHelpers";

const REGION_DETAIL_STACK_DIRECTION = mobileStyleBreakpoint<StackDirection>(
  "column",
  "row"
);

const REGION_DETAIL_STACK_SPACING = mobileStyleBreakpoint<string | number>(
  "14px",
  6
);

const RegionDetails = ({
  isDisabled,
  disableCreationOnlyFields,
}: {
  isDisabled: boolean;
  disableCreationOnlyFields: boolean;
}) => {
  const {
    formState: { errors },
  } = useFormContext<CropYearDetailFields>();
  const { fields } = useFieldArray<CropYearDetailFields>({
    name: "regionDetails",
  });
  const regionDetailStackDirection = useBreakpointValue(
    REGION_DETAIL_STACK_DIRECTION
  );
  const { data: regions, isLoading } = useRegionOptions();

  if (isLoading) {
    return <FormSkeletonLoadingState rowCount={2} />;
  }

  return (
    <Stack spacing={2}>
      {fields.map((field, index) => (
        <Stack spacing={4} key={field.id}>
          <TextHeading headingType="h6" color="greyFactor.4">
            {regions?.find((region) => region.id === field.regionId)?.name}
          </TextHeading>
          <Stack
            spacing={REGION_DETAIL_STACK_SPACING}
            direction={regionDetailStackDirection}
          >
            <FormInputs
              inputs={[
                {
                  id: `regionDetails.${index}.multiplier`,
                  label: "Multiplier",
                  isRequired: true,
                  component: StandardInput,
                  type: "number",
                  errorMsg: errors.regionDetails?.[index]?.multiplier?.message,
                  isInvalid: !!errors.regionDetails?.[index]?.multiplier,
                  isDisabled: disableCreationOnlyFields,
                  step: 0.01,
                },
                {
                  id: `regionDetails.${index}.estimatedTonsPerAcre`,
                  label: "Estimated tons per acre",
                  isRequired: true,
                  component: EstimatedYieldInput,
                  errorMsg:
                    errors.regionDetails?.[index]?.estimatedTonsPerAcre
                      ?.message,
                  isInvalid:
                    !!errors.regionDetails?.[index]?.estimatedTonsPerAcre,
                  isDisabled,
                },
              ]}
            />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default RegionDetails;
