import { useQuery } from "react-query";
import { DelayedContractDetailsResponse } from "api";
import { usePricingOptionService } from "contexts/ServiceProvider";
import { BaseApiError } from "services/apiHelpers";

export const GET_DELAYED_PRICING_QUERY_KEY = "get-delayed-pricing";

const useGetDelayedPricingCalculatedContract = ({
  cropYearId,
  farmId,
  futuresPrice,
  basis,
  tons,
  dateOfContract,
  cashPrice,
  isManuallyCreated,
}: {
  cropYearId: string | undefined;
  farmId: string | undefined;
  futuresPrice?: number;
  basis: number;
  tons: number;
  dateOfContract: string | undefined;
  cashPrice: number | undefined;
  isManuallyCreated: boolean;
}) => {
  const pricingOptionService = usePricingOptionService();
  const delayedPricingConditions = cropYearId && farmId;

  return useQuery<DelayedContractDetailsResponse, BaseApiError>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      GET_DELAYED_PRICING_QUERY_KEY,
      {
        cropYearId,
        farmId,
        futuresPrice,
        basis,
        tons,
        dateOfContract,
        cashPrice,
        isManuallyCreated,
      },
    ],
    queryFn: () =>
      delayedPricingConditions
        ? pricingOptionService.getDelayedPricingDetailsCalculated({
            cropYearId,
            farmId,
            futuresPrice,
            basis,
            tons,
            dateOfContract,
            cashPrice,
            isManuallyCreated,
          })
        : Promise.reject("Missing pricing cropYear ID, farm ID, or tons"),
    enabled: !!delayedPricingConditions,
  });
};

export default useGetDelayedPricingCalculatedContract;
