import { useMutation } from "react-query";

import useDashboardRefetchQueries from "./useDashboardRefetchQueries";
import { SetMonthlyAveragePriceRequest } from "api";
import { useCropYearService } from "contexts/ServiceProvider";
import { MutationHookResult } from "hooks/hookTypeHelpers";
import CropYearService from "services/CropYearService";

export const SET_MONTHLY_AVERAGE_PRICING_QUERY_KEY =
  "crop-year-set-monthly-average-pricing";

type SetMonthlyAveragePricingMutationResult = MutationHookResult<
  Awaited<ReturnType<CropYearService["setMonthlyAverage"]>>,
  SetMonthlyAveragePriceRequest
>;

const useSetMonthlyAverage = (
  cropYearId?: string,
  month?: string
): SetMonthlyAveragePricingMutationResult => {
  const cropYearService = useCropYearService();
  const { refetch } = useDashboardRefetchQueries();

  return useMutation({
    mutationKey: [
      SET_MONTHLY_AVERAGE_PRICING_QUERY_KEY,
      { id: cropYearId, month },
    ],
    mutationFn: (monthlyAverageInfo) =>
      cropYearId && month
        ? cropYearService.setMonthlyAverage(
            cropYearId,
            month,
            monthlyAverageInfo
          )
        : Promise.reject("Must provide crop year id and month"),
    onSuccess: refetch,
  });
};

export default useSetMonthlyAverage;
