import { useMutation, useQueryClient } from "react-query";

import { UpdateUserRequest } from "api";
import { useUserService } from "contexts/ServiceProvider";
import { MutationHook, MutationHookResult } from "hooks/hookTypeHelpers";
import { GET_FARMS_QUERY_KEY } from "hooks/farm/useFarmsList";
import { GET_FARM_INFO_QUERY_KEY } from "hooks/farm/farm-detail/useFarmDetail";
import UserService from "services/UserService";
import { GET_USERS_QUERY_KEY } from "./useUsersList";
import { GET_USER_DETAILS_QUERY_KEY } from "./useUserDetails";
import { ROLES_FOR_USER_QUERY_KEY } from "./useGetRoles";

type UpdateUserDetailsMutationResult = MutationHookResult<
  Awaited<ReturnType<UserService["updateUser"]>>,
  UpdateUserRequest
>;

const useUpdateUserDetailsMutation = (
  id: string
): UpdateUserDetailsMutationResult => {
  const userService = useUserService();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["update-user", { id }],
    mutationFn: (userInfo) => {
      const allRequired = Object.values(userInfo).every((value) => !!value);
      if (!allRequired) {
        throw new Error("Missing user info");
      }

      return userService.updateUser(id, userInfo);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.refetchQueries([GET_USERS_QUERY_KEY]),
        queryClient.refetchQueries([GET_USER_DETAILS_QUERY_KEY, { id }]),
        queryClient.refetchQueries([ROLES_FOR_USER_QUERY_KEY, { id }]),
        queryClient.refetchQueries([GET_FARMS_QUERY_KEY]),
        queryClient.refetchQueries([GET_FARM_INFO_QUERY_KEY]),
      ]),
  });
};

const useUpdateUserDetails = (
  id: string
): MutationHook<UpdateUserDetailsMutationResult, "onUpdate"> => {
  const {
    mutate: onUpdate,
    mutateAsync: onUpdateAsync,
    ...rest
  } = useUpdateUserDetailsMutation(id);

  return { onUpdate, onUpdateAsync, ...rest };
};

export default useUpdateUserDetails;
