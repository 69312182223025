import { ReactElement, useState } from "react";
import { Button, Stack } from "@chakra-ui/react";

import { AveragePricingHeader } from "./EditAveragePricingModal";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import useGetPricingOptionDetails from "hooks/pricing-option/useGetPricingOptionDetails";
import { MonthlyAverageListResponseDto } from "api";
import { capitalize } from "utils/formatFunctions";
import ModalWithSlideout from "components/ModalWithSlideout/ModalWithSlideout";
import PartialAveragePricingForm from "forms/pricing/PartialAveragePricingForm/PartialAveragePricingForm";
import {
  DEFER_PAYMENT_OPTION_NO,
  DEFER_PAYMENT_OPTION_YES,
} from "components/inputs/DeferPaymentSelectInput";
import { useCropYearContext } from "contexts/CropYearContext";
import BaseSlideout from "./BaseSlideout";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import MonthlyAverageModalContent from "./components/MonthlyAverageModalContent";
import PostHarvestAveragePricingModalContent from "./components/PostHarvestAveragePricingModalContent";
import TextHeading from "components/TextHeading";
import { parseDate } from "utils/dateUtils";

const PostHarvestEditAveragePricingModal = ({
  pricingOptionId,
  isOpen,
  onClose,
}: {
  pricingOptionId: string;
  isOpen: boolean;
  onClose: () => void;
}): ReactElement => {
  const [farmId] = useSelectedFarmId();
  const { isEmployee, isEmployeeAdmin } = useUserRoleFlags();
  const {
    cropYearDetail,
    isLoading: isCropYearLoading,
    cropYear,
  } = useCropYearContext();
  const { data, isLoading } = useGetPricingOptionDetails(
    pricingOptionId,
    farmId
  );
  const [selectedMonth, setSelectedMonth] =
    useState<MonthlyAverageListResponseDto>();

  const ids = {
    pricingOptionId,
    farmId,
    cropYearId: cropYear?.id,
  };
  const defaultValues = {
    deferPayment: data?.deferPayment
      ? DEFER_PAYMENT_OPTION_YES
      : DEFER_PAYMENT_OPTION_NO,
    paymentDate: data?.defermentDate
      ? parseDate(data.defermentDate)
      : parseDate(cropYearDetail?.defermentDateStart ?? ""),
  };
  const cropYearValues = {
    defermentDate: cropYearDetail?.defermentDateStart
      ? parseDate(cropYearDetail.defermentDateStart)
      : new Date(),
    firstPaymentDateWithoutReferral:
      cropYearDetail?.firstPaymentDateWithoutDeferral
        ? parseDate(cropYearDetail.firstPaymentDateWithoutDeferral)
        : new Date(),
  };

  const RVAdminSlideOut =
    !selectedMonth && data ? (
      <PartialAveragePricingForm
        ids={ids}
        defaultValues={defaultValues}
        averagePrice={data}
        cropYearValues={cropYearValues}
        onClose={onClose}
      />
    ) : null;

  const FarmAdminSlideOut = !selectedMonth ? (
    <Stack spacing={6}>
      <BaseSlideout data={data} />
      <Button w={"100%"} variant={"outline"} onClick={onClose}>
        Close
      </Button>
    </Stack>
  ) : null;

  return (
    <ModalWithSlideout
      isLoading={isLoading || !!isCropYearLoading}
      modalHeader={
        selectedMonth ? (
          <TextHeading pb={10}>
            {capitalize(selectedMonth.month.toLowerCase())} Average
          </TextHeading>
        ) : (
          <AveragePricingHeader />
        )
      }
      modalBody={
        selectedMonth ? (
          <MonthlyAverageModalContent
            monthData={selectedMonth}
            onClose={() => setSelectedMonth(undefined)}
          />
        ) : (
          <PostHarvestAveragePricingModalContent
            data={data}
            isLoading={isLoading}
            setSelectedMonth={setSelectedMonth}
          />
        )
      }
      modalFooter={null}
      modalSlideout={
        isEmployee || isEmployeeAdmin ? RVAdminSlideOut : FarmAdminSlideOut
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default PostHarvestEditAveragePricingModal;
